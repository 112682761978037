import React from "react";
import parse from "html-react-parser";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import styles from "./HelpFromTutor.module.css";
import { Button } from "react-bootstrap";
import { userID } from "../../../services/Authentication/AuthService";
const OpenRequests = ({ openRequests, addStudent }) => {
  if (openRequests && openRequests.length === 0) {
    return (
      <DisplayErrorMessage message="No open requests found" errorSize="big" />
    );
  }
  function checkSub(data) {
    if (data) {
      const result = data.filter(e => e.userID === userID());
      console.log(result)
      return result.length === 0 ? true : false
    }
  }
  return (
    <React.Fragment>
      {openRequests.length > 0 &&
        openRequests.map((q) => {
          if (q.status === "PENDING" && q.role === "student") {
            return (
              <div className={`cardDiv ${styles.tutorDiv}`} key={q._id}>
                <h5
                  title="Curriculum"
                  className={styles.curriculum}
                  style={{ fontFamily: "roboto" }}
                >
                  {q.curriculum} {"  -"}
                </h5>
                <div
                  title="Subjet"
                  className={styles.subject}
                  style={{ fontFamily: "roboto" }}
                >
                  {q.subject} {"-"}
                </div>
                <div
                  title="Topic"
                  className={styles.topic}
                  style={{ fontFamily: "roboto" }}
                >
                  {q.topics ? q.topics.join(", ") : ""}
                </div>
                {/* <div title="Chapter" className={styles.chapter}>
                  {q.chapter}
                </div> */}

                <hr className={styles.hr} />
                <p style={{ fontFamily: "roboto" }}>
                  {q.desc ? parse(q.desc) : ""}
                </p>
                <div className={styles.tutorFeatures}>
                  <p className={styles.status} style={{ fontFamily: "roboto" }}>
                    Status : Pending
                  </p>
                </div>
                {q.createdBy !== userID() && (<>
                  {checkSub(q.students) ? (<>
                    <br />
                    <div>
                      <Button onClick={() => addStudent(q._id)}>Subscribe</Button>
                    </div>
                  </>) : (<>
                    <br />
                    <div>
                      <Button disabled>Subscribed</Button>
                    </div>
                  </>)}
                </>)}
              </div>
            );
          }
        })}
    </React.Fragment>
  );
};

export default OpenRequests;
