import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";

class EventInfoModal extends Component {
  state = {
    modalVisible: false,
  };

  constructor(props) {
    super(props);
    this.props.childRef(this);
  }

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  handleEventShow = () => {
    this.setState({ modalVisible: true });
  };

  render() {
    const e = this.props.eventInfo;

    return (
      <>
        <Modal
          size="md"
          show={this.state.modalVisible}
          onHide={this.handleClose}
          className="eventModal"
          backdrop="static"
          centered
        >
          <Modal.Header style={{ fontSize: "18px", fontFamily: "roboto" }}>
            <Modal.Title style={{ fontSize: "18px", fontFamily: "roboto" }}>
              {e.title}
            </Modal.Title>
            <span
              onClick={this.handleClose}
              style={{
                border: "2px solid black",
                cursor: "pointer",
                padding: "2px 10px",
                background: "rgb(233, 236, 239)",
              }}
            >
              {" "}
              X
            </span>
          </Modal.Header>
          <Modal.Body>
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>Curriculum</label>
              <span style={{ fontFamily: "roboto" }}>{e.curriculum}</span>
            </div>
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>Subject</label>
              <span style={{ fontFamily: "roboto" }}>{e.subject}</span>
            </div>
            {/* <div class="text-view">
              <label>Topics</label>
              <span>{e.topics ? e.topics.join(", ") : ""}</span>
            </div> */}
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>Description</label>
              <span style={{ fontFamily: "roboto" }}>{e.desc}</span>
            </div>
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>Start</label>
              <span style={{ fontFamily: "roboto" }}>
                {moment(e.start).format("YYYY-MM-DD hh:mm a")}(GMT +5:30)
              </span>
            </div>
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>End</label>
              <span style={{ fontFamily: "roboto" }}>
                {moment(e.end).format("YYYY-MM-DD hh:mm a")}(GMT +5:30)
              </span>
            </div>
            {/* <div class="text-view">
              <label>Students</label>
              <span>
                <ol>
                  {e.studentEmails &&
                    e.studentEmails.map((item, i) => <li>{item.firstName}</li>)}
                </ol>
              </span>
            </div> */}
            <div class="text-view">
              <label style={{ fontFamily: "roboto" }}>Status</label>
              <span style={{ fontFamily: "roboto" }}>
                {e.status === "REJECTED"
                  ? e.status +
                    " (" +
                    e.rejectedTeacher.length +
                    "/" +
                    e.requestedTeacher.length +
                    ")"
                  : e.status}
              </span>
            </div>
            {/* <div class="text-view">
              <label>Invited Teachers</label>
              <span>
                <ol>
                  {e.requestedTeacher
                    ? e.requestedTeacher.map((item, index) => {
                        return (
                          <li key={index}>
                            <span>{item.email}</span>
                          </li>
                        );
                      })
                    : "N/A"}
                </ol>
              </span>
            </div> */}
            {/* <div class="text-view">
              <label>Rejected Teachers</label>
              <span>
                {e.rejectedTeacher && e.rejectedTeacher.length > 0 ? (
                  <ol>
                    {e.rejectedTeacher.map((item, index) => {
                      return (
                        <li key={index}>
                          <span>{item.email}</span>
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  "N/A"
                )}
              </span>
            </div> */}
            {/* <div class="text-view">
              <label>Accepted Teacher</label>
              <span>
                {e.status === "ACCEPTED" ? e.acceptedTeacher.email : "N/A"}
              </span>
            </div> */}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EventInfoModal;
