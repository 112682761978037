import { Add, BorderColor, CopyAll, Edit, RemoveRedEye, Delete } from "@mui/icons-material"
import "./Notification.css"
import { Link } from "react-router-dom"
import { getAllNews, deleteNews } from "../../../services/Admin/commonService"
import { useState } from "react"
import { useEffect } from "react"
import moment from "moment"
import { Backdrop, CircularProgress } from "@mui/material";

export const NotificationListing = () => {
    const [newsList, setNewsList] = useState([]);
    const [open,setOpen] =useState(true)
    const getAll = async () => { await 
       getAllNews().then(res => setNewsList(res.data.data));setOpen(false) }
    const deleteData = async (id) => { await deleteNews(id).then(res => getAll()) }
    useEffect(async () => {
        getAll()
    }, [])
    console.log(newsList)
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <div className="notification_table">
            <div className="notification_table-top">
              <div className="head_table">
                <h1 style={{ fontFamily: "roboto" }}>
                  News and Upcoming Events
                </h1>
              </div>
              <div className="btn_table">
                <Link to="/admin/add-notification">
                  <button id="add_btn">Add Notification</button>
                </Link>
              </div>
            </div>
            <div className="table_container">
              <div className="table-responsive">
                <table class="table">
                  <thead color="red">
                    <tr>
                      <th style={{ fontFamily: "roboto" }}>News Head</th>
                      <th style={{ fontFamily: "roboto" }}>Publish Date</th>
                      <th style={{ fontFamily: "roboto", minWidth: "180px" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newsList.reverse().map((e) => (
                      <>
                        <tr>
                          <td style={{ fontFamily: "roboto" }}>{e.message}</td>
                          <td style={{ fontFamily: "roboto" }}>
                            {moment(e.time).format("do MMMM YYYY")}
                          </td>
                          <td>
                            <Link to={`/admin/update-notification/${e._id}`}>
                              <button
                                className="del_btn mr-2"
                                style={{ backgroundColor: " #FFE7A8" }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColor
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </b>
                                </span>
                              </button>
                            </Link>
                            <button
                              className="del_btn ml-2 "
                              style={{
                                backgroundcolor: "rgb(228, 152, 152)",
                                marginLeft: "10px",
                                borderRadius: "3px",
                              }}
                              onClick={() => deleteData(e._id)}
                            >
                              <span style={{ color: "#ed2d2d" }}>
                                <Delete
                                  style={{
                                    color: "#ed2d2d",
                                    fontSize: "13px",
                                  }}
                                />
                                <b
                                  style={{
                                    fontSize: "11px",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  Delete
                                </b>
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}