import React from "react";
import { NavLink } from "react-router-dom";
import "./side.css";
import styles from "./sidebar.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
// import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();
  let userId = pathname.split("/");
  let id = userId[userId.length - 1];
  let token = userId[userId.length - 2];
  const test = `/admin/edit-curriculum/${id}`;
  const viewCurri = `/admin/view-curriculum/${id}`;
  return (
    <aside className={`${styles.mainSidebar}`}>
      <div className={`navbar-header  ${styles.navbarHeader}`}></div>
      <ul className={`navbar-nav ml-auto`} style={{ textDecoration: "none" }}>
        <li className="nav-item">
          <Link
            className={`navbar-brand  ${styles.navbarBrand}`}
            to="/admin/admin-dashboard"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Admin
          </Link>
        </li>
        <li className="nav-item">
          <NavLink
            to="/admin/admin-dashboard"
            className="nav-link"
            style={{ color: "#fff" }}
            // isActive={() => ["/events", "/myevents"].includes(pathname)}
          >
            <i className={`las la-home ${styles.sidebaricons}`}></i>
            Home
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/curriculum"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/curriculum",
                "/admin/add-curriculum",
                test,
                viewCurri,
              ].includes(pathname)
            }
          >
            <i className={`las la-graduation-cap ${styles.sidebaricons}`}></i>
            Curriculum
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/subject"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/subject",
                "/admin/add-subject",
                `/admin/edit-subject/${id}`,
                `/admin/view-subject/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-book-reader ${styles.sidebaricons}`}></i>
            Subject
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink
            to="/admin/course-content"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/course-content",
                "/admin/add-course-content",
                `/admin/edit-course-content/${id}`,
                `/admin/view-course-content/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-book-open ${styles.sidebaricons}`}></i>
            Chapter
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/question"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/question",
                "/admin/add-question",
                `/admin/edit-question/${id}`,
                `/admin/view-question/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-question-circle ${styles.sidebaricons}`}></i>
            Question Library
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/template"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/template",
                "/admin/add-template",
                `/admin/edit-template/${id}`,
                `/admin/view-template/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-chalkboard  ${styles.sidebaricons}`}></i>
            Templates
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/assessment"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/assessment",
                `/admin/edit-asseement/${id}`,
                "/admin/add-assessment",
                `/admin/view-assessment/${token}/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-clipboard ${styles.sidebaricons}`}></i>
            Assessment
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/staff"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                "/admin/staff",
                "/admin/add-staff",
                "/admin/add-staff-role",
                `/admin/edit-staff/${id}`,
              ].includes(pathname)
            }
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            Staff
          </NavLink>
        </li>

        <li className="nav-item dropdown">
          <NavLink
            to="/admin/video"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
                `/admin/edit-video/${id}`,
                `/admin/view-video/${id}`,
                "/admin/video",
                "/admin/add-exercise",
                "/admin/add-video",
                "/admin/add-read",
              ].includes(pathname)
            }
          >
            <i className={`las la-video ${styles.sidebaricons}`}></i>
            Video
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/student"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              ["/admin/student",
               `/admin/student-view/${id}`,
               `/admin/student-edit/${id}`,

              ].includes(pathname)
            }
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            Student
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/HrModule"
            className="nav-link"
            style={{ color: "#fff" }}
            // isActive={() => [].includes(pathname)}
          >
            <i className={`las la-user ${styles.sidebaricons}`}></i>
            HR Module
          </NavLink>
        </li>
        {/* <li className="nav-item ">
          <NavLink to="/admin/org-admin" className="nav-link">
            <i className={`las la-graduation-cap ${styles.sidebaricons}`}></i>
            Org-Admin
          </NavLink>
        </li> */}
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/schedule"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-calendar ${styles.sidebaricons}`}></i>
            Calendar
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/images"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-image ${styles.sidebaricons}`}></i>
            Images
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/session-admin"
            className="nav-link"
            style={{ color: "#fff" }}
          >
            <i className={`las la-sync ${styles.sidebaricons}`}></i>
            Session
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/notification-listing"
            className="nav-link"
            style={{ color: "#fff" }}
            isActive={() =>
              [
               `/admin/update-notification/${id}`,
               "/admin/notification-listing"

              ].includes(pathname)
            }
          >
            <i className={`las la-sync ${styles.sidebaricons}`}></i>
            Notifications
          </NavLink>
        </li>
        
        <li className="nav-item dropdown">
          <NavLink
            to="/admin/address"
            className="nav-link"
            style={{ color: "#fff" }}

            isActive={() =>
              [
               `/admin/address`
              

              ].includes(pathname)
            }
          >
            <i className={`las la-sync ${styles.sidebaricons}`}></i>
            Address
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
