import "./Notification.css"
import { Link } from "react-router-dom"
import { addNews } from "../../../services/Admin/commonService"
import { useState } from "react"
import { userID } from "../../../services/Authentication/AuthService"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import LoadingModal from "../../Share/LoadingModal"

export const AddNotification = () => {
    const history = useHistory()
    const [url, setUrl] = useState()
    const [message, setMessage] = useState()
    const [loader,setLoader]= useState(false)

    const submitNews = async () => {
        setLoader(true)
        let payload = {
            "sender_id": userID(),
            "path": url,
            "message": message
        }
        await addNews(payload).then(res => { 
            setLoader(false)
            history.push("/admin/notification-listing") 
        })
    }
    return (
      <>
        <div className="Addnotification_content">
          <LoadingModal visible={loader} />
          <div className="notification-head">
            <h1 style={{ fontFamily: "roboto" }}>
              Add News and Upcoming Events
            </h1>
            <div className="cancel_btn">
              <Link to="/admin/notification-listing">
                <button id="cancel_btn">Cancel</button>
              </Link>
            </div>
          </div>
          <div className="notification_container mb-3">
            <div className="notification_inputs mx-auto">
              <p style={{ fontFamily: "roboto" }}>Notification Heading :</p>
              <textarea
                className="w-100 p-2"
                rows="4"
                onChange={(e) => setMessage(e.target.value)}
                style={{ fontFamily: "roboto" }}
              ></textarea>
              <br />
              <br />
              <p style={{ fontFamily: "roboto" }}>Notification Url :</p>
              <textarea
                className="w-100 p-2"
                rows="4"
                onChange={(e) => setUrl(e.target.value)}
                style={{ fontFamily: "roboto" }}
              ></textarea>
              <br />
              <div className="submit_btn">
                <button id="submit_btn" onClick={() => submitNews()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}