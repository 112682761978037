import http from "../httpService";
import { apiUrl, api } from "../../config.json";

export const register = (payload) => {
  return http.post(api + "/user/register", payload);
};

export const googlelog = (payload) => {
  return http.post(api + '/user/googleLogin', payload)
};
export const verifyEmail = (payload) => {
  return http.get(api + '/user/emailVerify/' + payload)
};
export const passwordChange = (payload) => {
  return http.post(api + '/user/verifyUser', payload)
};
export const newPassword = (payload) => {
  return http.post(api + '/change-password', payload)
};

// export const findMail = (payload) => {
//   return http.post(api + '/findGmail', payload)
// };

export const login = (payload) => {
  return http.post(api + "/user/login", payload);
};

export const setToken = (token) => {
  sessionStorage.setItem("token", token.toString());
};

export const getAuthToken = () => {
  return sessionStorage.getItem("token");
};

const parseJwt = (token) => {
  if (token) {
    const basedUrls = token.split(".");
    const base64Url = basedUrls[1];
    return JSON.parse(atob(base64Url));
  }
};

export const getPayLoad = () => {
  return parseJwt(getAuthToken());
};

export const userInfo = () => {
  return getPayLoad();
};

export const userEmail = () => {
  return getPayLoad().email;
};

export const userRole = () => {
  return getPayLoad().role;
};

export const userID = () => {
  return getPayLoad().userID;
  // return 17;

};
export const ID = () => {
  return getPayLoad()._id;
  // return 17;

};


export const userCurriculumCode = () => {
  return getPayLoad().curriculumCode;
  // return "TEST";

};
export const assignCurrculum = () => {
  return getPayLoad().assignCurrculum;
  // return "TEST";

};
export const userCurriculum = () => {
  return getPayLoad().curriculum;
  // return "TEST";
};

export const userCurriculumsList = () => {
  return getPayLoad().userCurriculum;
};

export const teacherSubject = () => {
  return getPayLoad().subject;


};
export const orderPayment = (payload) => {
  return http.post(api + "/user/orderPayment", payload);
};
export const verfyPayment = (payload) => {
  return http.post(api + "/user/verifyOrder", payload);
};

export const verifyUser = (payload) => {
  return http.post(api + "/user/verifyUser", payload);
};
export const forgetPassword = (payload) => {
  return http.post(api + "/user/forgetPassword", payload);
};


// export const getOrderPayment = (payload) => {
//   return http.post(api + "/post/orderPayment", payload);
// };