import React, { useState, useEffect, Fragment } from 'react'
import styles from './teacherSession.module.css';
import FlatList from 'flatlist-react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const TeacherSessionComp = ({ sessionList, statusChange, loading, changeDateHandler, changeTimeHandler }) => {
  const [value, setValue] = useState(null)
  const [datas, setData] = useState()
  const [open, setOpen] = useState(false);
  const [view, setView] = useState({});
  useEffect(() => {
    if (!loading) {
      setValue(null)
    }
  }, [loading])

  return (
    <div className="container p-0">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="session_heading mt-2 w-100">
            <h1 className="form-heading" style={{ fontFamily: "roboto" }}>Session</h1>
            <Dialog
              open={open}
              onClose={!open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"

            // size="xl"
            >
              <DialogTitle id="alert-dialog-title">
                {"Location and Facilities"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">

                  <h6>
                    Building No. :{" "}
                    {view.address ? view.address.houseNo : ""}{" "}
                  </h6>
                  <h6>
                    Area :{" "}
                    {view.address ? view.address.area : ""}{" "}
                  </h6> <h6>
                    Near By :{" "}
                    {view.address ? view.address.nearBy : ""}{" "}
                  </h6> <h6>
                    University :{" "}
                    {view.address ? view.address.university : ""}{" "}
                  </h6> <h6>
                    Campus :{" "}
                    {view.address ? view.address.branch : ""}{" "}
                  </h6> <h6>
                    Room No. :{" "}
                    {view.address ? view.address.room_no : ""}{" "}
                  </h6> <h6>
                    Floor :{" "}
                    {view.address ? view.address.floor : ""}{" "}
                  </h6> <h6>
                    Block :{" "}
                    {view.address ? view.address.block : ""}{" "}
                  </h6>
                  <h6>
                    Country :{" "}
                    {view.address ? view.address.country : ""}{" "}
                  </h6>
                  <h6>
                    State :{" "}
                    {view.address ? view.address.state : ""}{" "}
                  </h6>
                  <h6>
                    City :{" "}
                    {view.address ? view.address.city : ""}{" "}
                  </h6>
                  <h6>
                    Facilities :{" "}
                    {view.facilities ? view.facilities.join(", ") : ""}{" "}
                  </h6>
                  {view.mode && view.mode === "Offine + Online Session" && (<>
                    <h6>
                      Facilities :{" "}
                      <a href={datas.meetingLink} target="_blank">Meeting Link</a>{" "}
                    </h6>
                  </>)}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <FlatList
            list={sessionList}
            renderWhenEmpty={() => <div>No session Found!</div>}
            renderItem={(q, index) => {
              let start, startT, end, endT, len;
              try {
                // start =moment.parseZone(q.start).local().format('YYYY-MM-DD');
                // startT = moment.parseZone(q.start).local().format('HH:mm');
                // end = moment.parseZone(q.end).local().format('YYYY-MM-DD');
                // endT = moment.parseZone(q.end).local().format('HH:mm');
                start = moment.parseZone(q.start).format("YYYY-MM-DD");
                startT = moment.parseZone(q.start).format("hh:mm A");
                end = moment.parseZone(q.end).format("YYYY-MM-DD");
                endT = moment.parseZone(q.end).format("hh:mm A");
                len = q.students.length;
              } catch (err) {
                console.log(err, "err");
              }

              let check = new Date(q.end).getTime();

              console.log("hcelk", check);
              let timePassed;

              if (check < Date.now()) {
                console.log(startT, "startT");

                console.log("---------");
                timePassed = true;
              } else {
                timePassed = false;
              }
              return (
                <div>
                  <></>
                  {/* <img className='mr-3' src="https://img.icons8.com/ios-glyphs/30/000000/arrow.png" /> */}
                  <h5 className={`card-title mb-1 ${styles.cardheading}`} style={{ fontFamily: "roboto" }}>
                    ID: {q.sessionID}- {q.curriculum} - {q.subject} - {q.topics}{" "}
                    <br />
                  </h5>

                  <div className="card p-3 mb-3">
                    <div className="card-body row p-0">
                      <div className="col-md-6">
                        <p className={`mb-2 ${styles.head}`} style={{ fontFamily: "roboto" }}>{q.title}</p>
                        {q.desc && <p className={`mb-2 `} style={{ fontFamily: "roboto" }}>{parse(q.desc)}</p>}

                        <div className="row">
                          <>
                            {" "}
                            <div className="col-5 d-flex flex-column align-items-start">
                              <p className="">Start</p>

                              <p className={`${styles.date}`} style={{ fontFamily: "roboto" }}>{start}</p>
                              <p className={`${styles.date} mt-2`} style={{ fontFamily: "roboto" }}>{startT}</p>
                            </div>
                            <div className="col-5 d-flex flex-column align-items-start">
                              <p>End</p>
                              <p className={`${styles.date} `} style={{ fontFamily: "robot" }}>{end}</p>
                              <p className={`${styles.date} mt-2`} style={{ fontFamily: "roboto" }}>{endT}</p>
                            </div>
                          </>

                        </div>
                      </div>
                      <div className="col-md-3">
                        <p className={`mb-2 ${styles.head} text-center `} style={{ fontFamily: "roboto" }}>
                          Total Students
                        </p>
                        <p className={`${styles.num} `} style={{ fontFamily: "roboto" }}>{len}</p>
                      </div>
                      <div className="col-md-3 text-center pt-5">
                        {q.status === "PENDING" && timePassed && !loading && (
                          <>
                            <p className={`mb-2 ${styles.head} text-center`} style={{ fontFamily: "roboto" }}>
                              Session date has passed
                            </p>
                          </>
                        )}
                        {q.status === "PENDING" && !timePassed && !loading && (
                          <>
                            {" "}
                            <button
                              className={`btn btn-danger ${styles.click} px-3`}
                              style={{ borderRadius: "20px" }}
                              onClick={() => {
                                statusChange(q, "REJECTED");
                                setValue(q.sessionID);
                              }}
                            >
                              <i class="fa-solid fa-xmark"></i> Cancel
                            </button>
                            <br />
                            <button
                              className={`btn btn-success ${styles.click}`}
                              style={{ borderRadius: "20px", fontFamily: "roboto" }}
                              onClick={() => {
                                statusChange(q, "ACCEPTED");
                                setValue(q.sessionID);
                              }}
                            >
                              <i class="fa-solid fa-check"></i> Accept
                            </button>{" "}
                          </>
                        )}

                        {loading && value !== q.sessionID && (
                          <>
                            {" "}
                            <button
                              className={`btn btn-danger ${styles.click}`}
                              style={{ borderRadius: "20px", fontFamily: "roboto" }}
                              onClick={() => {
                                statusChange(q, "REJECTED");
                                setValue(q.sessionID);
                              }}
                            >
                              Cancel
                            </button>
                            <br />
                            <button
                              className={`btn btn-success ${styles.click}`}
                              style={{ borderRadius: "20px", fontFamily: "roboto" }}
                              onClick={() => {
                                statusChange(q, "ACCEPTED");
                                setValue(q.sessionID);
                              }}
                            >
                              Accept
                            </button>{" "}
                          </>
                        )}

                        {q.status === "ACCEPTED" &&
                          q.sessionMode.mode === "Online Session" &&
                          !loading && (
                            <>
                              <p
                                className={`mb-2 ${styles.amount} text-center`} style={{ fontFamily: "roboto" }}
                              >
                                {q.status}
                              </p>
                              <button
                                className={`btn btn-info ${styles.join}`}
                                style={{ borderRadius: "20px", fontFamily: "roboto" }}
                                onClick={() => window.open(q.meetingLink)}
                              >
                                <a style={{ fontFamily: "roboto" }}>Join</a>
                              </button>
                            </>
                          )}
                        {q.status === "ACCEPTED" &&
                          q.sessionMode.mode === "Offine Session" &&
                          !loading && (
                            <>
                              <p
                                className={`mb-2 ${styles.amount} text-center`}
                                style={{ fontFamily: "roboto" }}
                              >
                                {q.status}
                              </p>
                              <button
                                className={`btn btn-info ${styles.join}`}
                                style={{ borderRadius: "20px" }}
                                onClick={() => {
                                  setOpen(true);
                                  setView(q.sessionMode);
                                }}
                              >
                                <a style={{ fontFamily: "roboto" }}>View Loaction</a>
                              </button>
                            </>
                          )}
                        {q.status === "ACCEPTED" &&
                          q.sessionMode.mode === "Offine + Online Session" &&
                          !loading && (
                            <>
                              <p
                                className={`mb-2 ${styles.amount} text-center`}
                                style={{ fontFamily: "roboto" }}
                              >
                                {q.status}
                              </p>
                              <button
                                className={`btn btn-info ${styles.join}`}
                                style={{ borderRadius: "20px" }}
                                onClick={() => {
                                  setOpen(true);
                                  setView(q.sessionMode);
                                  setData(q)
                                }}
                              >
                                <a style={{ fontFamily: "roboto" }}>View Loaction + link</a>
                              </button>
                            </>
                          )}
                        {q.status === "REJECTED" && !loading && (
                          <>
                            <p className={`mb-2 ${styles.head} text-center`} style={{ fontFamily: "roboto" }}>
                              {q.status}
                            </p>
                          </>
                        )}
                        {loading && value === q.sessionID && (
                          <button className="btn btn-sm btn-primary ">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only" style={{ fontFamily: "roboto" }}>Loading...</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TeacherSessionComp