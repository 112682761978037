import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import style from "./assessment.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Backdrop, CircularProgress } from "@mui/material";

const Assessment = ({ assessmentList, search, searchAssessment, open }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <h1
          className="form-heading"
          style={{ color: "#4A1D75", fontFamily: "roboto" }}
        >
          Assessment List
        </h1>
        <div className="assessmentHeader">
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchAssessment(text)}
              placeholder="Search	"
            />
          </div>
          <div>
            <Link
              to={`/admin/add-assessment`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Assessment
            </Link>
          </div>
        </div>
        <div className="col-md-12 table_container">
          {/* <div className="card"> */}
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table">
                <thead>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}>ID</th>
                    <th style={{ fontFamily: "roboto" }}>Assessment Name</th>
                    <th style={{ fontFamily: "roboto" }}>Type</th>
                    <th style={{ fontFamily: "roboto" }}>Curriculum</th>
                    <th style={{ fontFamily: "roboto" }}>Start Date</th>
                    <th style={{ fontFamily: "roboto" }}>End Date</th>
                    <th style={{ fontFamily: "roboto", minWidth: "260px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assessmentList &&
                    assessmentList.length > 0 &&
                    assessmentList.map((a) => {
                      return (
                        <tr key={a._id}>
                          <td style={{ fontFamily: "roboto" }}>{a.testID}</td>
                          <td style={{ fontFamily: "roboto" }}>
                            {a.assessmentName}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>{a.type}</td>
                          <td style={{ fontFamily: "roboto" }}>
                            {a.curriculum}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {moment(a.startdate).format("MM/DD/YYYY")}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {moment(a.enddate).format("MM/DD/YYYY")}{" "}
                          </td>
                          <td
                          // style={{
                          //   display: "flex",
                          //   width: "100%",
                          // }}
                          >
                            <Link
                              to={`/admin/view-assessment/${a.testID}/${a.curriculumCode}`}
                            >
                              <button
                                className="btn btn-sm btn-info"
                                style={{
                                  backgroundColor: "#DEF2E9",
                                  // marginLeft: "10px",
                                }}
                              >
                                <span style={{ color: "#49AF48" }}>
                                  <RemoveRedEyeIcon
                                    style={{
                                      color: "#49AF48",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    View
                                  </b>
                                </span>
                              </button>
                            </Link>

                            <Link to={`/admin/edit-asseement/${a.testID}`}>
                              <button
                                className="btn btn-lg btn-info"
                                style={{
                                  backgroundColor: " #FFE7A8",
                                  marginLeft: "10px",
                                  padding: "0.3rem 0.9rem",
                                }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </b>
                                </span>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Assessment;
