import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Images/log.png";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
import { Button, Modal } from "react-bootstrap";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import moment from "moment";
import styles from "./Topbar.module.css";
import { getCurriculumList } from "../../../services/Admin/commonService";
import TextField from "@material-ui/core/TextField";
import { getUpdateCurriculum } from "../../../services/Admin/curriculumService";
import {
  userCurriculum,
  userCurriculumCode,
  userID,
} from "../../../services/Authentication/AuthService";
import { getNotify, deleteNotify } from "../../../services/Admin/commonService";
import { apiUrl } from "../../../config.json";
import ChatBot from "../ChatBot/ChatBot";
import { useMediaQuery } from "@mui/material";
const Topbar = () => {
  let history = useHistory();
  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  let notification = ["No Notification avaliable"];
  const [show, setShow] = useState(false);
  const [cur, setCurList] = useState(false);
  const [selected, setSelected] = useState({});
  const [error, setError] = useState();
  const [notify, setNotify] = useState([]);
  const [logoImg, setLogoImg] = useState();

  const matches = useMediaQuery('(min-width:992px)');

  async function getNotification() {
    getNotify({
      for_msg: "student",
    }).then((res) => {
      let noti = res.data.data;
      let cur = sessionStorage.getItem("currculum").split(",");
      let aa = noti.filter((e) => {
        if (cur.indexOf(e.curriculum) !== -1) {
          return e;
        }
      });
      console.log(aa);
      console.log("the noti check", res);
      setNotify(aa.reverse());
    });
  }
  const listCurricluim = () => {
    getCurriculumList()
      .then((res) => {
        if (res.status === 200) {
          setCurList(res.data.data);
        }
      })
      .catch((err) => {
        setCurList([]);
      });
  };
 useEffect(()=>{
  setLogoImg(localStorage.logo);
 },[])

  useEffect(() => {
    setSelected({
      curriculum: userCurriculum(),
      curriculumCode: userCurriculumCode(),
    });
    listCurricluim();
    getNotification();
  }, [sessionStorage.getItem("currculum")]);
  const updateCur = () => {
    let payload = {
      userID: userID(),
      curriculum: selected.curriculum,
      curriculumCode: selected.curriculumCode,
    };

    getUpdateCurriculum(payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.token = res.data.token;
          sessionStorage.loginType = "student";
          handleClose();
          window.location.reload();
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });

  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Autocomplete1
              id="combo-box-demo"
              options={cur}
              getOptionLabel={(option) => option.curriculum}
              onChange={(event, value) => {
                if (value === null) {
                  setSelected("");
                  return;
                }
                setSelected({
                  curriculum: value.curriculum,
                  curriculumCode: value.curriculumCode,
                });
              }}
              disableClearable={true}
              defaultValue={{
                curriculum: selected.curriculum ? selected.curriculum : "",
              }}
              style={{ width: "100%", height: "38px" }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            {error && <p>{error}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateCur}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <nav class="navbar navbar-expand-lg navbar-light bg-light w-100 ps-2 py-3">
          <Link class="navbar-brand" to="/student/dashboard">
            <img src={logoImg} width="120px" height="50px" alt=""></img>
          </Link>
          <div className="d-flex">
            {!matches ? (
              <div className="d-flex me-3">
                <div className="nav-item dropdown me-3">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="las la-bell fs-3"></i>
                    <span
                      class="badge rounded-pill badge-notification bg-danger"
                      style={{
                        fontSize: "11px",
                        padding: "3px 5px",
                        margin: "-8px",
                        position: "relative",
                        top: "-19px",
                        left: "-5px",
                      }}
                    >
                      {notify.length}
                    </span>
                  </NavLink>
                  <div
                    className={`dropdown-menu ${styles.dropdownMenu} p-0 h-auto`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className={`${styles.studentNotification} p-0`}>
                      {notify.map((res) => {
                        return (
                          <>
                            <div className={`${styles.iconText} `}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  padding: "15px 10px",
                                  cursor: "pointer",
                                  paddingBottom: "0px",
                                }}
                                onClick={() => {
                                  history.push(res.path);
                                  deleteNotify(res._id).then((res) => {
                                    getNotification();
                                  });
                                }}
                              >
                                <i class="las la-check-circle"></i>
                                <p style={{ paddingLeft: "2%" }}>
                                  {res.message}
                                </p>
                              </span>
                              <p
                                style={{
                                  display: "block",
                                  paddingBottom: "10px",
                                  paddingLeft: "45px",
                                  color: "#ccc9c9",
                                }}
                              >
                                {moment
                                  .utc(res.time)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                            <hr className="m-0"></hr>
                          </>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        position: "sticky",
                        zIndex: "9999",
                        bottom: "0px",
                        padding: "15px 15px",
                        background: "white",
                        borderTop: "1px solid #ccc9c9",
                        cursor: "pointer",
                      }}
                    >
                      Mark all as read
                    </div>
                  </div>
                </div>
                <div className={`nav-item dropdown`}>
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="las la-user-circle fs-3"></i>
                  </NavLink>

                  <div
                    className={`dropdown-menu ${styles.dropdownMenu1}`}
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/student/profile">
                      Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="#" onClick={Logout}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            class={`collapse navbar-collapse w-100 ${styles.dd} ${
              matches ? "d-flex justify-content-end" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul class={`navbar-nav mr-auto  d-flex justify-content-between`}>
              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/dashboard"
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active1" : ""} test`}
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/curriculum"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  Curriculum
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/assignments"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  Assessment
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/my-queries/open"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  My Queries
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/help-from-tutor/open"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  Help-From-Tutor
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/StudentTraining"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  Student Training
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  data-target="#navbarSupportedContent"
                  to="/student/sessions"
                  exact
                  activeClassName="active"
                  // className="nav-link text-dark "
                  className={`${({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""} test`}
                >
                  Sessions
                </NavLink>
              </li>
            </ul>
          </div>
          {matches ? (
            <>
              <div className="nav-item dropdown me-3">
                <span
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="las la-bell fs-3"></i>
                  <span
                    class="badge rounded-pill badge-notification bg-danger"
                    style={{
                      fontSize: "11px",
                      padding: "3px 5px",
                      margin: "-8px",
                      position: "relative",
                      top: "-19px",
                      left: "-5px",
                    }}
                  >
                    {notify.length}
                  </span>
                </span>
                <div
                  className={`dropdown-menu ${styles.dropdownMenu} p-0 h-auto`}
                  aria-labelledby="navbarDropdown"
                >
                  <div className={`${styles.studentNotification} p-0`}>
                    {notify.map((res) => {
                      return (
                        <>
                          <div className={`${styles.iconText} `}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "start",
                                padding: "15px 10px",
                                cursor: "pointer",
                                paddingBottom: "0px",
                              }}
                              onClick={() => {
                                history.push(res.path);
                                deleteNotify(res._id).then((res) => {
                                  getNotification();
                                });
                              }}
                            >
                              <i class="las la-check-circle"></i>
                              <p
                                style={{
                                  paddingLeft: "2%",
                                  fontFamily: "roboto",
                                }}
                              >
                                {res.message}
                              </p>
                            </span>
                            <p
                              style={{
                                display: "block",
                                paddingBottom: "10px",
                                paddingLeft: "45px",
                                color: "#ccc9c9",
                              }}
                            >
                              {moment
                                .utc(res.time)
                                .local()
                                .startOf("seconds")
                                .fromNow()}
                            </p>
                          </div>
                          <hr className="m-0"></hr>
                        </>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      position: "sticky",
                      zIndex: "9999",
                      bottom: "0px",
                      padding: "15px 15px",
                      background: "white",
                      borderTop: "1px solid #ccc9c9",
                      cursor: "pointer",
                      fontFamily: "roboto",
                    }}
                  >
                    Mark all as read
                  </div>
                </div>
              </div>
              <div className={`nav-item dropdown`}>
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="las la-user-circle fs-3"></i>
                </NavLink>

                <div
                  className={`dropdown-menu ${styles.dropdownMenu1}`}
                  aria-labelledby="navbarDropdown"
                >
                  <Link
                    className="dropdown-item"
                    to="/student/profile"
                    style={{ fontFamily: "roboto" }}
                  >
                    Profile
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={Logout}
                    style={{ fontFamily: "roboto" }}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </nav>

     
      </header>
      <ChatBot />
    </>
  );
};

export default Topbar;
