import React from "react";

import Button from "../common/button";

import Input from "../common/input";
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {
    curriculumListFun,
    subjectListFun,
    topicListFun,
    chapterListFun,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const AddRead = (props) => {
    const history = useHistory();

    const {
        mode,
        videoData,
        curriculumList,
        subjectsList,
        topicsList,
        chaptersList,
        handleInput,
        handleFileChange,
        handleFileUpload,
        fileUpload,
        uploadFileChange,
        error,
        videoList,
        videoListDropDown,
        handleInput2
    } = props;

    const data = videoData;
    let curriculumOptions = curriculumListFun(curriculumList);
    let subjectOptions = subjectListFun(subjectsList);
    let topicOptions = topicListFun(topicsList);
    let chapterOptions = chapterListFun(chaptersList);
    // console.log('videoList-' + JSON.stringify(chapterOptions))
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            {/* <div className="d-flex flex-row"> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "99%",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="form-heading" style={{ fontFamily: "roboto" }}>
                  {mode} Reading material
                </h1>
              </div>
              <div>
                <button
                  className="btn btn-sm  mb-4"
                  onClick={() => history.goBack()}
                  style={{
                    borderRadius: "40px",
                    backgroundColor: "#4A1D75",
                    border: "2px solid #4A1D75",
                    width: "140px",
                    textAlign: "center",
                    padding: "6px",
                    color: "white",
                    height: "auto",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            {/* </div> */}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {/* <Select
                                        name="curriculum"
                                        optname="curriculum"
                                        label="Curriulum Name"
                                        value={data.curriculum ? data.curriculum : ""}
                                        options={curriculumOptions}
                                        onChange={(e) => {
                                            handleInput(e, "curriculum");
                                        }}
                                    /> */}
                    <div className="form-group">
                      <label className="label-heading" htmlFor="curriculum">
                        curriculums
                      </label>

                      {mode === "Add" && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={curriculumOptions}
                          getOptionLabel={(option) => option.curriculum}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "curriculum");
                              return;
                            }
                            handleInput2(value.curriculum, "curriculum");
                          }}
                          disableClearable={true}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {mode === "Edit" && data.curriculum && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={curriculumOptions}
                          getOptionLabel={(option) => option.curriculum}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "curriculum");
                              return;
                            }
                            handleInput2(value.curriculum, "curriculum");
                          }}
                          disableClearable={true}
                          defaultValue={{
                            curriculum: data.curriculum ? data.curriculum : "",
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <Select
                                        name="subject"
                                        optname="subject"
                                        label="Subject Name"
                                        value={data.subject ? data.subject : ""}
                                        options={subjectOptions}
                                        onChange={(e) => handleInput(e, "subject")}
                                    /> */}
                    <div className="form-group ">
                      <label className="label-heading" htmlFor="curriculum">
                        subject
                      </label>

                      {mode === "Add" && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={subjectOptions}
                          getOptionLabel={(option) => option.subject}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "subject");
                              return;
                            }
                            handleInput2(value.subject, "subject");
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {mode === "Edit" && data.subject && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={subjectOptions}
                          getOptionLabel={(option) => option.subject}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "subject");
                              return;
                            }
                            handleInput2(value.subject, "subject");
                          }}
                          disableClearable={true}
                          defaultValue={{
                            subject: data.subject ? data.subject : "",
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {/* <ErrorMessage component={TextError} name="subject" /> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <Select
                                        name="topic"
                                        optname="topic"
                                        label="Topic Name"
                                        value={data.topic ? data.topic : ""}
                                        options={topicOptions ? topicOptions : []}
                                        onChange={(e) => handleInput(e, "topic")}
                                    /> */}
                    <div className="form-group ">
                      <label className="label-heading" htmlFor="curriculum">
                        topic
                      </label>

                      {mode === "Add" && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={topicOptions}
                          getOptionLabel={(option) => option.topic}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "topic");
                              return;
                            }
                            handleInput2(value.topic, "topic");
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {mode === "Edit" && data.topic && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={topicOptions}
                          getOptionLabel={(option) => option.topic}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "topic");
                              return;
                            }
                            handleInput2(value.topic, "topic");
                          }}
                          disableClearable={true}
                          defaultValue={{ topic: data.topic ? data.topic : "" }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {/* <ErrorMessage component={TextError} name="topic" /> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <Select
                                        name="chapter"
                                        optname="chapter"
                                        label="Chapter Name"
                                        value={data.chapter ? data.chapter : ""}
                                        options={chapterOptions ? chapterOptions : []}
                                        onChange={(e) => handleInput(e, "chapter")}
                                    /> */}
                    <div className="form-group ">
                      <label className="label-heading" htmlFor="curriculum">
                        chapter
                      </label>

                      {mode === "Add" && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={chapterOptions}
                          getOptionLabel={(option) => option.chapter}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "chapter");
                              return;
                            }

                            handleInput2(value.chapter, "chapter");
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {mode === "Edit" && data.chapter && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={chapterOptions}
                          getOptionLabel={(option) => option.chapter}
                          onChange={(event, value) => {
                            if (value === null) {
                              handleInput2("", "chapter");
                              return;
                            }
                            console.log("ddddddd");
                            handleInput2(value.chapter, "chapter");
                          }}
                          disableClearable={true}
                          defaultValue={{
                            chapter: data.chapter ? data.chapter : "",
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                      {/* <ErrorMessage component={TextError} name="chapter" /> */}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                    <Select
                                        name="video"
                                        optname="fileName"
                                        label="Video Name"
                                        value={data.fileName ? data.fileName : ""}
                                        options={fileName ? chapterOptions : []}
                                        onChange={(e) => handleInput(e, "chapter")}
                                    />
                                </div> */}
                  <div className="col-md-6">
                    {/* <Select
                                        name="videofileName"
                                        optname={`fileName`}
                                        label="Video Name"
                                        value={data.videofileName ? data.videofileName : ""}
                                        options={videoListDropDown ? videoListDropDown : []}
                                        onChange={(e) => handleInput(e, "video")}
                                    /> */}
                    <div className="form-group ">
                      <label className="label-heading" htmlFor="curriculum">
                        Video Name
                      </label>
                      {mode === "Add" && (
                        <Autocomplete1
                          disablePortal
                          id="combo-box-demo"
                          // className="form-control"
                          options={videoListDropDown}
                          getOptionLabel={(option) => option.fileName}
                          onChange={(event, value) => {
                            console.log("alue", value);
                            if (value === null) {
                              handleInput2("", "video");
                              return;
                            }

                            handleInput2(value.fileName, "video");
                          }}
                          style={{ width: "100%", height: "38px" }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                                    <Input
                                        type="file"
                                        name="video"
                                        label="Video"
                                        onChange={handleFileChange}
                                    />
                                </div> */}
                  <div className="col-md-12">
                    <Input
                      type="file"
                      accept="application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      name="Upload file"
                      label="Upload File"
                      onChange={uploadFileChange}
                    />
                  </div>
                </div>
                {error && (
                  <div className="text-center text-danger">
                    <label>{error}</label>
                  </div>
                )}
                <div className="text-center">
                  <Button
                    className="btn btn-sm "
                    label={"upload file"}
                    onClick={fileUpload}
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                    }}
                  />
                </div>
                {/* <div className="text-center">
                                <Button
                                    className="btn btn-sm btn-primary"
                                    label={"Submit"}
                                    onClick={handleFileUpload}
                                />
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AddRead;
