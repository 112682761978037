import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import parse from "html-react-parser";
import {
  InputComponent,
  SelectComponent,
  DatepickerComponent,
  Button,
  TimeInputComponent,
} from "../../../common/FormComponents";
import { toCapitalWord } from "../../../utils/utils";
import { assignmentValidation } from "../../../validations/AdminValidations";
import "../../../common/Formik/Common.css";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TextError from "../../../common/Formik/TextError";
import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { getCreateAddress } from "../../../services/Admin/AddressService";

const AddAssessment = (props) => {
  const [cur, setCue] = useState("");
  const [editcur, seteditCur] = useState("");
  const [codeData, setCode] = useState();
  const [addSubject, setAddSubject] = useState("");
  const [addressId, setAddressId] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [lab, setLab] = useState("");
  const [category, setCategory] = useState("");
  const [assType, setAssType] = useState("");
  const history = useHistory();

  const {
    data,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    templatesList,
    questionsList,
    handleInput,
    handleDates,
    handleInputLab,
    handleGetList,
    handleGetTemplateQuestions,
    handleSubmit,
    handleInput1,
    mode,
    time,
    saveTime,
    durationTime,
  } = props;
  console.log(questionsList);

  const [questionMarks, setQuestionMarks] = useState()
  const [maxQues, setMaxQues] = useState(false);
  const [durations, setduration] = useState([]);

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  console.log(templatesList.length, "dtada");
  let questionIds = [];
  let questionTypes = [];
  let correctionModeValue;
  let totalMarks = 0;
  const optioncheckbox = (e, qID, marks, questionType) => {
    if (e.target.checked) {
      questionIds.push({ questionID: qID });
      questionTypes.push(questionType);
      totalMarks += parseInt(marks);
    } else {
      let index = questionIds.findIndex((x) => x.questionID === qID);
      let index1 = questionTypes.indexOf(questionType);
      questionIds.splice(index, 1);
      questionTypes.splice(index1, 1);
      totalMarks -= parseInt(marks);
    }
    if (data.assignmentMode === "Mandatory") {
      if (
        questionIds.length <= data.maxQuestions &&
        totalMarks <= data.maxWeightage
      ) {
        // alert("add questions");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("max questions or max Weightage reached");
      }
    } else {
      if (questionIds.length <= data.maxQuestions && totalMarks <= data.maxWeightage) {
        // alert("add questions optional");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("optional max questions or max Weightage reached");
      }
    }

    function checkQuesionType(qt) {
      return qt === "Text";
    }

    let correctionMode = questionTypes.some(checkQuesionType);

    if (correctionMode == true) {
      correctionModeValue = "manual";
    } else {
      correctionModeValue = "automatic";
    }
  };

  const assignmentMode = (e) => {
    let value = e.target.value;
    data.optionalQuestions = "";
    data.optQuestionMarks = "";
    data.maxWeightage = "";
    if (value === "Mandatory") {
      setMaxQues(false);
    } else {
      setMaxQues(true);
    }
  };

  const optionalMaxWeitage = (e, name) => {
    if (name === "optionalQuestions") {
      data.maxWeightage = e.target.value * data.optQuestionMarks;
    } else if (name === "optQuestionMarks") {
      data.maxWeightage = data.optionalQuestions * e.target.value;
    }
  };
  const getAddresslistingByCurr = async (subject) => {

    const payload = {
      curriculum: cur,
      curriculumCode: codeData.curriculumCode,
      subject: subject
    }
    await getCreateAddress(payload).then(res => {
      console.log(res.data.data)
      setAddressList(res.data.data)
    })
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="d-flex flex-row">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "99%",
                alignItems: "center",
              }}
            >
              <h1
                className="form-heading"
                style={{ color: "#4A1D75", fontFamily: "roboto" }}
              >
                Add Assessment
              </h1>
              <button
                className="btn btn-sm  mb-4"
                onClick={() => history.goBack()}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={data}
                validationSchema={assignmentValidation}
                validateOnMount
                enableReinitialize
                validateOnChange={false} // turn off fields validation
                validateOnBlur={false} // turn off fields validation
              >
                {(props) => {
                  console.log(props);
                  const { isValid } = props;
                  // console.log(isValid)
                  return (
                    <React.Fragment>
                      <Form>
                        <div className="row">
                          <Field
                            col="col-md-6"
                            label="Assessment Name"
                            name="assessmentName"
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                            value={
                              data.assessmentName ? data.assessmentName : ""
                            }
                          />

                          {/* <Field
                            col="col-md-6"
                            label="Assessment Type"
                            name="type"
                            value={data.type ? data.type : ""}
                            options={[
                              { _id: "1", name: "Public" },
                              { _id: "2", name: "Private" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e)}
                          /> */}
                          <div className="col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              Assessment Type
                            </label>

                            <select
                              className="w-100 form-select"
                              // value={data.type ? data.type : ""}
                              component={SelectComponent}
                              value={assType}
                              onChange={(e) => {
                                setAssType(e.target.value);

                                handleInput(e, "type");
                              }}
                            >
                              <option
                                defaultValue="select"
                                defaultChecked
                                selected="selected"
                                value={"select"}
                              >
                                select
                              </option>
                              <option>Public</option>
                              <option>Private</option>
                            </select>
                          </div>

                          {/* <Field
                            col="col-md-6"
                            label="Curriculum"
                            name="curriculum"
                            value={data.curriculum ? data.curriculum : ""}
                            optname="curriculum"
                            options={curriculumOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "curriculum")}
                          /> */}
                          <div className="form-group col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              curriculums
                            </label>

                            {mode === "Add" && (
                              
                              // <Autocomplete1
                              //   id="combo-box-demo"
                              //   // className="form-control"
                              //   options={curriculumOptions}
                              //   getOptionLabel={(option) => option.curriculum}
                              //   onChange={(event, value) => {
                              //     if (value === null) {
                              //       handleInput1("", "curriculum");
                              //       return;
                              //     }
                              //     handleInput1(value.curriculum, "curriculum");
                              //   }}
                              //   disableClearable={true}
                              //   style={{ width: "100%", height: "38px" }}
                              //   renderInput={(params) => (
                              //     <TextField {...params} variant="outlined" />
                              //   )}
                              // />
                              <select
                                className="w-100 form-select"
                                name="curriculum"
                                optname="curriculum"
                                label="Curriulum Name"
                                // value={data.curriculum ? data.curriculum : ""}
                                value={cur}
                                options={curriculumOptions}
                                onChange={(e) => {
                                  setCue(e.target.value);

                                  let curs = e.target.value
                                  const codeData = curriculumOptions.filter(res => res.curriculum === curs)
                                  setCode(codeData[0])

                                  handleInput(e, "curriculum");
                                }}
                              >
                                <option
                                  defaultValue="select"
                                  defaultChecked
                                  selected="selected"
                                  value={"select"}
                                >
                                  select
                                </option>
                                {curriculumList?.map((data1, index) => (
                                  <option key={index} value={data1.curriculum}>
                                    {" "}
                                    {data1.curriculum}{" "}
                                  </option>
                                ))}
                              </select>
                            )}
                            {mode === "Edit" && data.curriculum && (
                              // <Autocomplete1
                              //   id="combo-box-demo"
                              //   // className="form-control"
                              //   options={curriculumOptions}
                              //   getOptionLabel={(option) => option.curriculum}
                              //   onChange={(event, value) => {
                              //     if (value === null) {
                              //       handleInput1("", "curriculum");
                              //       return;
                              //     }
                              //     handleInput1(value.curriculum, "curriculum");
                              //   }}
                              //   disableClearable={true}
                              //   defaultValue={{
                              //     curriculum: data.curriculum
                              //       ? data.curriculum
                              //       : "",
                              //   }}
                              //   style={{ width: "100%", height: "38px" }}
                              //   renderInput={(params) => (
                              //     <TextField {...params} variant="outlined" />
                              //   )}
                              // />
                              <select
                                className="w-100 form-select"
                                name="curriculum"
                                optname="curriculum"
                                label="Curriulum Name"
                                // value={data.curriculum ? data.curriculum : ""}
                                value={editcur}
                                options={curriculumOptions}
                                onChange={(e) => {
                                  seteditCur(e.target.value);
                                  handleInput(e, "curriculum");
                                }}
                              >
                                <option
                                  defaultValue="select"
                                  defaultChecked
                                  selected="selected"
                                  value={"select"}
                                >
                                  select
                                </option>
                                {curriculumList?.map((data1, index) => (
                                  <option key={index} value={data1.curriculum}>
                                    {" "}
                                    {data1.curriculum}{" "}
                                  </option>
                                ))}
                              </select>
                            )}
                            <ErrorMessage
                              component={TextError}
                              name="curriculum"
                            />
                          </div>

                          {/* <Field
                            col="col-md-6"
                            name="subject"
                            label="Subject Name"
                            optname="subject"
                            value={data.subject ? data.subject : ""}
                            options={subjectOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "subject")}
                          /> */}
                          <div className="form-group col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              subject
                            </label>

                            {mode === "Add" && (
                              // <Autocomplete1
                              //   // id="combo-box-demo"
                              //   // className="form-control"
                              //   options={subjectOptions}
                              //   getOptionLabel={(option) => option.subject}
                              //   onChange={(event, value) => {
                              //     if (value === null) {
                              //       handleInput1("", "subject");
                              //       return;
                              //     }
                              //     handleInput1(value.subject, "subject");
                              //   }}
                              //   // style={{ width: '100%', height: '38px' }}
                              //   renderInput={(params) => (
                              //     <TextField {...params} variant="outlined" />
                              //   )}
                              // />
                              <select
                                name="subject"
                                className="w-100 form-select"
                                optname="subject"
                                label="Subject Name"
                                // value={data.subject ? data.subject : ""}
                                value={addSubject}
                                options={subjectOptions}
                                onChange={(e) => {
                                  setAddSubject(e.target.value);
                                  getAddresslistingByCurr(e.target.value)
                                  handleInput(e, "subject");
                                }}
                              >
                                <option
                                  defaultValue="select"
                                  defaultChecked
                                  selected="selected"
                                  value={"select"}
                                >
                                  select
                                </option>
                                {subjectsList?.map((data2, index) => (
                                  <option key={index} value={data2.subject}>
                                    {" "}
                                    {data2.subject}{" "}
                                  </option>
                                ))}
                              </select>
                            )}
                            {mode === "Edit" && data.subject && (
                              <Autocomplete1
                                id="combo-box-demo"
                                // className="form-control"
                                options={subjectOptions}
                                getOptionLabel={(option) => option.subject}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "subject");
                                    return;
                                  }
                                  handleInput1(value.subject, "subject");
                                }}
                                disableClearable={true}
                                defaultValue={{
                                  subject: data.subject ? data.subject : "",
                                }}
                                style={{ width: "100%", height: "38px" }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            <ErrorMessage
                              component={TextError}
                              name="subject"
                            />
                          </div>

                          {/* <Field
                            col="col-md-6"
                            name="topic"
                            label="Topic Name"
                            optname="topic"
                            value={data.topic ? data.topic : ""}
                            options={topicOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "topic")}
                          /> */}
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              topic
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={topicOptions}
                              getOptionLabel={(option) => option.topic}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "topic")
                                  return
                                }
                                handleInput1(value.topic, "topic")
                              }}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.topic && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={topicOptions}
                              getOptionLabel={(option) => option.topic}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "topic")
                                  return
                                }
                                handleInput1(value.topic, "topic")
                              }}
                              disableClearable={true}
                              defaultValue={{ topic: data.topic ? data.topic : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="topic" />
                          </div> */}
                          {/* <Field
                            col="col-md-6"
                            name="chapter"
                            label="Chapter Name"
                            optname="chapter"
                            value={data.chapter ? data.chapter : ""}
                            options={chapterOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "chapter")}
                          /> */}
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              chapter
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={chapterOptions}
                              getOptionLabel={(option) => option.chapter}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "chapter")
                                  return
                                }

                                handleInput1(value.chapter, "chapter")
                              }}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.chapter && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={chapterOptions}
                              getOptionLabel={(option) => option.chapter}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "chapter")
                                  return
                                }
                                handleInput1(value.topic, "chapter")
                              }}
                              disableClearable={true}
                              defaultValue={{ chapter: data.chapter ? data.chapter : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="chapter" />
                          </div> */}

                          <DatepickerComponent
                            col="col-md-6"
                            name="startdate"
                            placeholderText="MM/DD/YYYY"
                            label="Start Date (MM/DD/YYYY)"
                            minDate={new Date()}
                            selected={data.startdate ? data.startdate : ""}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => handleDates(date, "startdate")}
                          />

                          <DatepickerComponent
                            col="col-md-6"
                            name="enddate"
                            label="End Date (MM/DD/YYYY)"
                            placeholderText="MM/DD/YYYY"
                            startDate={data.startdate}
                            endDate={data.enddate}
                            minDate={data.startdate}
                            selected={data.enddate ? data.enddate : ""}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => {
                              console.log("", data);
                              handleDates(date, "enddate");
                            }}
                          />

                          {/* 
                          <Field
                            col="col-md-6"
                            label="Start Time"
                            name="startTime"
                            component={TimeInputComponent}
                            onChange={(e) => saveTime(e.target.value, 'startTime')}

                            value={
                              time.startTime ? time.startTime : ""
                            }
                          />
                          <Field
                            col="col-md-6"
                            label="End Time"
                            name="endTime"
                            component={TimeInputComponent}
                            onChange={(e) => {
                              saveTime(e.target.value, 'endTime')
                            }}
                            value={
                              time.endTime ? time.endTime : ""
                            }
                          /> */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="endTime">Start Time</label>
                              <input
                                type="time"
                                className="form-control"
                                name="startTime"
                                onChange={(e) => {
                                  console.log("eee", e.target.value);
                                  saveTime(e.target.value, "startTime");
                                  console.log(e.target.value);
                                }}
                                value={time.startTime ? time.startTime : ""}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="endTime">End Time</label>
                              <input
                                type="time"
                                className="form-control"
                                name="endTime"
                                onChange={(e) => {
                                  console.log("eee", e.target.value);
                                  saveTime(e.target.value, "endTime");
                                }}
                                value={time.endTime ? time.endTime : ""}
                              />
                            </div>
                          </div>

                          {/* <Field
                            col="col-md-6"
                            name="assignmentMode"
                            label="Assessment  Mode"
                            value={
                              data.assignmentMode ? data.assignmentMode : ""
                            }
                            options={[
                              { _id: "1", name: "Mandatory" },
                              { _id: "2", name: "Optional" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => {
                              assignmentMode(e);
                              handleInput(e);
                            }}
                          /> */}
                          <div className="col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              Assessment Mode
                            </label>
                            <select
                              className="w-100 form-select"
                              onChange={(e) => {
                                assignmentMode(e);
                                handleInput(e, "assignmentMode");
                              }}
                            >
                              <option
                                defaultValue="select"
                                defaultChecked
                                selected="selected"
                                value={"select"}
                              >
                                select
                              </option>
                              <option value={"Mandatory"}>Mandatory</option>
                              <option value={"Optional"}>Optional</option>
                            </select>
                          </div>

                          <Field
                            col="col-md-6"
                            name="maxQuestions"
                            label="Max Questions"
                            value={data.maxQuestions ? data.maxQuestions : ""}
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                          />

                          {data.assignmentMode === "Optional" && (
                            <Field
                              col="col-md-6"
                              type="text"
                              name="optionalQuestions"
                              label="Optional Questions"
                              value={data.optionalQuestions}
                              component={InputComponent}
                              onChange={(e) => {
                                // optionalMaxWeitage(e, "optionalQuestions");
                                handleInput(e, "optionalQuestions");
                              }}
                            />
                          )}

                          {data.assignmentMode === "Optional" && (
                            <Field
                              col="col-md-6"
                              label="Question Marks"
                              name="optQuestionMarks"
                              // value={
                              //   data.optQuestionMarks
                              //     ? data.optQuestionMarks
                              //     : ""
                              // }
                              value={questionMarks}
                              options={[
                                { _id: "1", name: 1 },
                                { _id: "2", name: 2 },
                                { _id: "3", name: 3 },
                                { _id: "4", name: 5 },
                                { _id: "5", name: 10 },
                              ]}
                              component={SelectComponent}
                              onChange={(e) => {
                                optionalMaxWeitage(e, "optQuestionMarks");
                                handleInput(e, "optQuestionMarks");
                                setQuestionMarks(e.target.value);
                              }}
                            />
                            // <select

                            //   value={questionMarks}
                            //   onChange={(e) => {
                            //     optionalMaxWeitage(e, "optQuestionMarks");
                            //     handleInput(e);
                            //     setQuestionMarks(e.target.value)
                            //   }}
                            // >
                            //   <option>select</option>
                            //   <option id={1} name={1} value={1}>1</option>
                            //   <option id={2} name={2} value={2}>2</option>
                            //   <option id={3} name={3} value={3}>3</option>
                            // </select>
                          )}

                          <Field
                            col="col-md-6"
                            type="text"
                            name="maxWeightage"
                            label="Max Weightage"
                            value={data.maxWeightage ? data.maxWeightage : ""}
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                            disabled={maxQues}
                          />

                          {/* <div className="col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              Lab Session
                            </label>
                            <select
                              name="labSession"
                              component={SelectComponent}
                              className="w-100 form-select"
                              value={lab}
                              onChange={(e) => {
                                setLab(e.target.value);
                                console.log(e.target.value);

                                handleInputLab(e, "labSession");
                              }}
                            >
                              <option
                                defaultValue="select"
                                defaultChecked
                                selected="selected"
                                value={"select"}
                              >
                                select
                              </option>
                              <option value={"yes"}>Yes</option>
                              <option value={"no"}>No</option>
                            </select>
                          </div> */}

                          {/* <Field
                            col="col-md-6"
                            name="assessmentType"
                            label="Select Category"
                            value={
                              data.assessmentType ? data.assessmentType : ""
                            }
                            options={[
                              { _id: "1", name: "Questions" },
                              { _id: "2", name: "Use Template" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e)}
                          /> */}
                          {codeData && codeData.curriculumMode === "Offline"?(<>
                          
                            <div className="col-md-6">
                              <label
                                className="label-heading"
                                htmlFor="curriculum"
                              >
                                Address
                              </label>
                              <select
                                className="form-select"
                                value={addressId}
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  // const add = this.state.addressList.filter(es => es._id.toString() === e.target.value)
                                  // this.state.address = add[0]
                                  setAddressId(e.target.value);
                                  console.log(e.target.value, "1")
                                  handleInput(e, "addressId");

                                }}
                                required
                              >
                                <option value={"Select"} selected>{"Select "}</option>
                                {addressList.map((res, index) =>
                                (
                                  <option value={res._id} key={index}>
                                    {/* {res.houseNo + ", " + res.area + " near by " + res.nearBy + ", " + res.city + "-" + res.pincode + ", " + res.state + ", " + res.country} */}
                                    {res.title}

                                  </option>
                                )
                                )}
                              </select>
                            </div>
                          
                          </>):(<>
                            <div className="col-md-6">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              Select Category
                            </label>
                            <select
                              className="w-100 form-select"
                              // component={SelectComponent}
                              // value={
                              //   data.assessmentType ? data.assessmentType : ""
                              // }
                              value={codeData?.curriculumMode === "Offline"? "Question" : category}
                              // component={SelectComponent}
                              onChange={(e) => {
                                setCategory(e.target.value);
                                handleInput(e, "assessmentType");
                              }}
                            >
                              <option
                                defaultValue="select"
                                defaultChecked
                                selected="selected"
                                value={"select"}
                              >
                                select

                              </option>
                              <option>Questions</option>
                              <option> Use Template</option>
                            </select>
                          </div>
                          
                          
                          </>)}
                          
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="time-input" for="time-input">
                                Assessment Duration (HH:MM:SS)
                              </label>
                              {/* <input
                                type="time"
                                className="form-control without_ampm"
                                name="time-input"
                                step="1"
                                id="time-input"
                                // inKeyDown={(e)=>{console.log("eee", e.target.value)}}
                                onChange={(e,value) => {
                                 return console.log("eee", e.target.value, value,e  );
                                  // saveTime(e.target.value, "Duration");
                                }}
                                value={time.duration ? time.duration : ""}
                              /> */}
                              <br />
                              <input
                                class="in_put"
                                id="hh_1"
                                type="number"
                                min="0"
                                max="23"
                                placeholder="00"
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onChange={(e) => {
                                  setduration([
                                    e.target.value,
                                    durations[1],
                                    durations[2],
                                  ]);
                                }}
                              />
                              :
                              <input
                                class="in_put"
                                id="mm_1"
                                type="number"
                                min="0"
                                max="59"
                                placeholder="00"
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onChange={(e) => {
                                  setduration([
                                    durations[0],
                                    e.target.value,
                                    durations[2],
                                  ]);
                                }}
                              />
                              :
                              <input
                                class="in_put"
                                id="ss_1"
                                type="number"
                                min="0"
                                max="59"
                                placeholder="00"
                                onInput={(e) =>
                                  (e.target.value = e.target.value.slice(0, 2))
                                }
                                onChange={(e) => {
                                  console.log(durations);
                                  setduration([
                                    durations[0],
                                    durations[1],
                                    e.target.value,
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                          
                          {codeData && codeData.curriculumMode === "Offline" ? (<>

                            <Button
                              type="submit"
                              disabled={!isValid}
                              className="btn btn-primary form-btn mb-4"
                              label="Submit"
                             
                              onClick={() =>
                                handleSubmit(
                                  props,
                                  questionIds,
                                  correctionModeValue,
                                  codeData.curriculumMode
                                )
                              }
                              style={{
                                borderRadius: "40px",
                                backgroundColor: "#4A1D75",
                                border: "2px solid #4A1D75",
                                width: "140px",
                                textAlign: "center",
                                padding: "6px",
                                color: "white",
                                height: "auto",
                                // marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            />

                          </>) : (<>
                            <Button
                              type="submit"
                              className="btn btn-sm form-btn mb-4"
                              label="List"
                              disabled={!isValid}
                              onClick={() => {
                                handleGetList(props.values);
                                durationTime(durations);
                                console.log(props.values);
                              }}
                              style={{
                                borderRadius: "40px",
                                backgroundColor: "#4A1D75",
                                border: "2px solid #4A1D75",
                                width: "140px",
                                textAlign: "center",
                                padding: "6px",
                                color: "white",
                                height: "auto",
                                // marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            />
                          </>)}


                        </div>

                        {data.assessmentType.trim() === "Use Template" &&
                          templatesList &&
                          templatesList.length > 0 && (
                            <div className="col-md-12 row mt-3 mb-3 p-0">
                              {templatesList.length > 0 &&
                                templatesList.map((q) => {
                                  if (q.questionmarks === data.questionMarks) {
                                    return (
                                      <div className="col-md-6" key={q._id}>
                                        <input
                                          type="radio"
                                          name="templateRadio"
                                          onClick={() =>
                                            handleGetTemplateQuestions(
                                              q.templateID
                                            )
                                          }
                                        />{" "}
                                        &nbsp;
                                        {q.templateID} - {q.templateName}
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          )}

                        {questionsList && questionsList.length > 0 && (
                          <div className="col-md-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{ color: "#4A1D75" }}>ID</th>
                                  <th style={{ color: "#4A1D75" }}>Question</th>
                                  <th style={{ color: "#4A1D75" }}>
                                    Question Type
                                  </th>
                                  <th style={{ color: "#4A1D75" }}>Marks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {questionsList.length > 0 &&
                                  questionsList.map((ql) => {
                                    if (data.assignmentMode === "Optional") {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>
                                              {ql.question
                                                ? parse(ql.question)
                                                : ""}{" "}
                                            </span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    } else if (
                                      data.assignmentMode === "Mandatory"
                                    ) {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>
                                              {ql.question
                                                ? parse(ql.question)
                                                : ""}
                                            </span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                              </tbody>
                            </table>
                            <Button
                              type="submit"
                              className="btn btn-primary form-btn mb-4"
                              label="Submit"
                              disabled={!isValid}
                              onClick={() =>
                                handleSubmit(
                                  props,
                                  questionIds,
                                  correctionModeValue
                                )
                              }
                              style={{ marginTop: "56px" }}
                            />
                          </div>
                        )}
                      </Form>
                    </React.Fragment>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssessment;
