import React, { Component } from "react";
import Assessment from "../../components/admin/assessment/assessment";
import AddAssessment from "../../components/admin/assessment/addAsseement";
import ViewAssessment from "../../components/admin/assessment/viewAssessment";
import { userID, userRole } from "../../services/Authentication/AuthService";
import { assignmentValues } from "../../constants/AdminInitialValues";
import LoadingModal from "../../components/Share/LoadingModal";
import {
  getTemplateList,
  getTemplateQuestions,
} from "../../services/Admin/templateService";
import { getQuestion } from "../../services/Admin/questionService";
import {
  createAssessment,
  getAssessmentsList,
  getAssignmentByID,
} from "../../services/Admin/assessmentService";

import { getCurriculumCodeFun } from "../../utils/utils";
import {
  getAllCurriculumList,
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,
} from "../../components/Share/GetCurriculumDetails";
import { duration } from "moment/moment";
import { addNotify } from "../../services/Admin/commonService";
import EditAsseement from "../../components/admin/assessment/editAsseement";

class AssessmentContainer extends Component {
  state = {
    mode: "",
    assignmentData: assignmentValues,
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    templatesList: [],
    questionsList: [],
    assessmentList: [],
    assignmentInvidual: [],
    search: "",
    assessmentListAll: [],
    time: { startTime: "", endTime: "" },
    isLoading: false,
    durations: "",
    open: true,
    addressId: "",
  };

  /* const [open, setOpen] = useState(true); */
  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-assessment") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/view-assessment/:id/:cc") {
      this.setState({ mode: "View" });
    } else if (pathName === "/admin/assessment") {
      this.setState({ mode: "List" });
    }
  };
  saveTime = (time, type) => {
    this.setState({ time: { ...this.state.time, [type]: time } });
  };
  durationTime = (time) => {
    let times = time.join(":");
    this.setState({ durations: times });
  };
  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.assignmentData };
    const { options, value, selectedIndex } = input;

    // console.log('opopop', input);
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[name] = optValue;
      console.log(optValue, name, "in if");
    } else {
      data[input.name] = input.value;

      console.log(input.value, input.name, "else");
    }
    // if (input.name === "endTime") {
    //   console.log('sas', input.value);
    //   data['endTime'] = new Date(input.value);
    //   this.setState({ assignmentData: data });
    //   console.log('dsd', data);
    //   return
    // }
    if (name === "addressId") {
      console.log(input.value);
      this.setState({ addressId: input.value });
    }
    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      console.log(curriculumCodeValue);
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      data.curriculumCode = curriculumCodeValue;
      this.setState({ subjectsList });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(data.curriculumCode, input.value);
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        input.value
      );
      this.setState({ chaptersList });
    }

    if (data.assessmentName !== "Get Template") {
      this.setState({ templatesList: {} });
    }

    if (data.assessmentName !== "Questions") {
      this.setState({ questionsList: {} });
    }
    if (data.assessmentName !== "")
      if (data.optionalQuestions === "Optional") {
        // alert("123");
        // data.optionalQuestions = "0";
        this.setState({ optionalQuestions: "" });
      }
    if (data.optQuestionMarks === "Optional") {
      this.setState({ optQuestionMarks: "" });
    }
    this.setState({ assignmentData: data });
  };
  handleInput1 = async (input, name) => {
    const data = { ...this.state.questionValues };
    if (name) {
      data[name] = input;
    }

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      data.curriculumCode = curriculumCodeValue;

      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList, curriculumCode: curriculumCodeValue });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(this.state.curriculumCode, input);
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        this.state.curriculumCode,
        data.subject,
        input
      );
      this.setState({ chaptersList });
    }

    this.setState({ questionValues: data });
  };
  handleDates = (date, name) => {
    const data = { ...this.state.assignmentData };
    data[name] = date;
    this.setState({ assignmentData: data });
  };

  handleInputLab = ({ currentTarget: input }, name) => {
    const data = { ...this.state.assignmentData };
    if (input.value === "yes") {
      data[name] = true;
    } else if (input.value === "n o") {
      data[name] = false;
    }
    this.setState({ assignmentData: data });
  };

  handleGetList = (values) => {
    // console.log(values)
    const type = values.assessmentType;
    // console.log(values.assessmentType);
    console.log(values);
    if (type === "Questions") {
      const payload = {
        query: {
          curriculumCode: values.curriculumCode,
          subject: values.subject.trim(),
          // topic: values.topic,
          // chapter: values.chapter,
          // role: "Admin",
        },
      };

      getQuestion(payload.query)
        .then((res) => {
          let data = res.data.data;
          let response = data.filter((e) => !e.question.includes("(copy)"));
          this.setState({
            questionsList: response,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      const payload = {
        curriculum: values.curriculum,
      };
      getTemplateList(payload)
        .then((res) => {
          let list = res.data.data.filter(
            (doc) =>
              doc.curriculum.trim() === values.curriculum.trim() &&
              doc.subject.trim() === values.subject.trim()
          );

          this.setState({
            templatesList: list,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  handleGetTemplateQuestions = async (templateId) => {
    const payload = {
      templateID: templateId,
    };
    getTemplateQuestions(payload)
      .then((res) => {
        let data = res.data.data.data2[0].Questions_DATA;
        let response = data.filter((e) => !e.question.includes("(copy)"));
        this.setState({
          questionsList: response,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleSubmit = (formik, questions, correctionModeValue, mode) => {
    console.log("correctionMode", correctionModeValue);
    console.log(formik);
    if (mode === "Offline" && this.state.addressId.length === 0) {
      alert("Please select Address");
    } else {
      if (formik.isValid && (questions.length > 0 || mode === "Offline")) {
        const data = formik.values;

        data["startTime"] = this.state.time.startTime;
        data["endTime"] = this.state.time.endTime;
        const payload = data;
        payload.questions = questions;
        payload.createDate = new Date();
        // payload.createdBy = userID();
        payload.correctionMode =
          correctionModeValue && correctionModeValue?.length !== 0
            ? "manual"
            : correctionModeValue;
        payload.duration = this.state.durations;
        payload.addressId = this.state.addressId;

        this.setState({ isLoading: true });
        createAssessment(payload)
          .then((res) => {
            // addNotify({
            //   curriculum: payload.curriculum,
            //   subject: payload.subject,
            //   status: "assessment",
            //   sender_id: userID(),
            //   message: `you have a new assessment of ${payload.subject} for ${payload.curriculum}`,
            //   for_msg: "teacher",
            //   path: "/teacher/assignments-list",
            //   sendBy:userRole(),
            //   time: new Date()
            // })
            addNotify({
              curriculum: payload.curriculum,
              subject: payload.subject,
              status: "assessment",
              sender_id: userID(),
              message: `you have a new assessment of subject - ${payload.subject} for curriculum - ${payload.curriculum}`,
              for_msg: "student",
              path: "/student/assignments",
              sendBy: userRole(),
              time: new Date(),
            });
            this.props.history.push("/admin/assessment");
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        alert("Please select questions");
      }
    }
  };

  getAssessmentsList = async () => {
    const payload = {};
    getAssessmentsList(payload)
      .then((res) => {
        this.setState({ open: false });
        this.setState({
          assessmentList: res.data.data,
          assessmentListAll: res.data.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getIndividualAssignment = async () => {
    this.setState({ open: true });
    const payload = {
      testID: this.props.match.params.id,
      curriculumCode: this.props.match.params.cc,
    };
    getAssignmentByID(payload)
      .then((res) => {
        console.log(res);
        this.setState({ assignmentInvidual: res.data.data });
        this.setState({ questionssss: res.data.data.testquestion });
        this.setState({ open: false });
        console.log("assignmentInvidual", res.data);
        console.log("payload", payload);

        console.log("questionssss", res.data.testquestion);
      })
      .catch((err) => {});
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    const { mode } = this.state;
    if (mode === "Add") {
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
    } else if (mode === "List") {
      this.getAssessmentsList();
    } else if (mode === "View") {
      this.getIndividualAssignment();
    }
  }
  searchAssessment = (event) => {
    this.setState({ search: event.target.value });
    let val = String(event.target.value)
      .toLowerCase()
      .trim();
    if (!val) {
      this.getAssessmentsList();
      return;
    }
    let filter = this.state.assessmentListAll.filter((q) => {
      // console.log('ass--',q)
      try {
        if (q.assessmentName.toLowerCase().indexOf(val) !== -1) {
          return q;
        }
      } catch (err) {}
    });
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ assessmentList: [...filter.reverse()] });
  };
  render() {
    let path = this.props.match.path;
    const {
      assignmentData,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      templatesList,
      questionsList,
      assessmentList,
      assignmentInvidual,
      questionssss,
    } = this.state;

    return (
      <React.Fragment>
        {path === "/admin/assessment" && (
          <Assessment
            assessmentList={assessmentList}
            searchAssessment={this.searchAssessment}
            search={this.state.search}
            open={this.state.open}
          />
        )}
        {path === "/admin/add-assessment" && (
          <AddAssessment
            mode={this.state.mode}
            data={assignmentData}
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            templatesList={templatesList}
            questionsList={questionsList}
            handleInput={this.handleInput}
            handleDates={this.handleDates}
            handleInputLab={this.handleInputLab}
            handleGetList={this.handleGetList}
            handleGetTemplateQuestions={this.handleGetTemplateQuestions}
            handleSubmit={this.handleSubmit}
            handleInput1={this.handleInput1}
            time={this.state.time}
            saveTime={this.saveTime}
            durationTime={this.durationTime}
          />
        )}
        {path === "/admin/view-assessment/:id/:cc" && (
          <ViewAssessment
            assignmentInvidual={assignmentInvidual}
            questionssss={questionssss}
            closeViewCourse={this.closeViewCourse}
            open={this.state.open}
          />
        )}
        {path === "/admin/edit-asseement/:id" && (
          <EditAsseement
            data={assignmentData}
            questionsList={questionsList}
            curriculumList={curriculumList}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default AssessmentContainer;
