import React, { useState, useEffect, Fragment } from "react";
import styles from "./adminSession.module.css";
import FlatList from "flatlist-react";
import Toggle from "react-toggle";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { getAllCurriculumList } from "../../Share/GetCurriculumDetails";
import "./toogle.css";
import { indigo } from "@material-ui/core/colors";
import Select from "../common/select";
import { getAllTeachers, inviteTeacher } from "../../Schedule/api";
import { curriculumListFun, teacherListFun } from "../../../utils/utils";
import moment from "moment";
import { Backdrop, CircularProgress, containerClasses } from "@mui/material";
import { Formik } from "formik";
import { indexOf, set } from "lodash";
import { getCreateAddress } from "../../../services/Admin/AddressService";
import FormikControl from "../../../common/Formik/FormikControl";
import { Delete } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import {deleteSession} from "../../../services/Admin/staffService"
const AdminSessionComp = ({

  changeTime,
  deleteAdminSession,
  removeTeacher,
  accTeacherList,
  addTeacher,
  sendMessage,
  handleClose,
  session,
  saveSession,
  teacherList,
  show,
  sendInvite,
  sessionList,
  statusChange,
  toogle,
  setToogle,
  getSession,
  texts,
  getSessionById,
  sessionData,
  setSessionData,
  curriculumList,
  subjectFuction,
  subjectList,
  teacherFunction,
  teacherData,
  updateSession,
  open,
}) => {

  const [show1, setShow1] = useState(false);
  const [mode, setmode] = useState();
  const [sessionType, setSessionType] = useState();
  const [date, setDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [teacher, setTeacher] = useState("")
  const [curriculum, setCurriculum] = useState("")
  const [curriculumCode, setCurriculumCode] = useState()
  const [subject, setSubject] = useState()
  const [facilities, setfacilities] = useState([])
  const [address, setAddress] = useState({})
  const [teacherDatas, setTeacherData] = useState([])
  const [addressList, setAddressList] = useState([])
  const [studentLimit, setStudentLimit] = useState(0)

  const [changes, setChanges] = useState(true)

  function showval1(e) {
    e.target.checked ? facilities.push(e.target.value) : facilities.splice(indexOf(facilities[e.target.value], 1))
    console.log(facilities)
  }
  function teachers(option, sess) {
    if (sess?.students?.length < studentLimit && sess.role === "student") {
      alert(`Total student should be more than ${studentLimit}`)
    } else {
      addTeacher(option)
    }

  }
  async function addressLists(cur, code, sub) {
    const payload = {
      curriculum: cur,
      curriculumCode: code,
      subject: sub
    }
    await getCreateAddress(payload).then(res => {
      console.log(res.data.data)
      setAddressList(res.data.data)
    })
  }
  useEffect(() => {
    if (sessionData) {
      setmode(sessionData.sessionMode.mode)
      setSessionType(sessionData.sessionType)
      setfacilities(sessionData.sessionMode.facilities)
      setAddress(sessionData.sessionMode.address ? sessionData.sessionMode.address : "")
      setCurriculum(sessionData.curriculum);
      setSubject(sessionData.subject)
      setDate(sessionData.start.split(" ")[0])
      setStartTime(sessionData.start.split(" ")[1])
      setEndTime(sessionData.end.split(" ")[1])
      setTitle(sessionData.title)
      setTeacher(sessionData.requestedTeacher.length > 0 ? sessionData.requestedTeacher[0].email : "");
      setTeacherData(sessionData.requestedTeacher)
      setCurriculumCode(sessionData.curriculumCode)
      subjectFuction(sessionData.curriculumCode)
      teacherFunction(sessionData.curriculumCode, sessionData.subject);
      addressLists(sessionData.curriculum, sessionData.curriculumCode, sessionData.subject)
    }
  }, [sessionData])

  const handleShow = () => {

    setShow1(true);
  };
  const handleClose2 = () => {
    setShow1(false);
  };

  const submitData = async () => {
    const payload = {
      title: title,
      curriculum: curriculum,
      curriculumCode: curriculumCode,
      subject: subject,
      requestedTeacher: teacherDatas,
      desc: desc,
      date: date,
      start: date + " " + startTime,
      end: date + " " + endTime,
      endTime: endTime,
      sessionType: sessionType,
      sessionMode: {
        mode: mode,
        address: address,
        facilities: facilities
      },
    }
    await updateSession(payload, sessionData._id)
    setShow1(false)
  }


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="form-heading" style={{ fontFamily: "roboto" }}>
              Session
            </h1>
            <div style={{ display: "flex" }}>
              <Toggle
                id="cheese-status"
                defaultChecked={toogle}
                onChange={() => setToogle((state) => !state)}
              />
              <p
                className="ml-4"
                htmlFor="cheese-status "
                style={{ marginLeft: "15px", fontFamily: "roboto" }}
              >
                {texts}
              </p>
            </div>
            <FlatList
              list={sessionList}
              renderItem={(q) => {
                // if (q.status === "ACCEPTED") {
                //   return;
                // }
                // if (!toogle && q.student.length < 10) {
                //     return;
                // }
                let start, end, len;

                start = q.start.split(" ")[0];
                end = q.end.split(" ")[0];
                len = q.students.length;
                return (
                  <div class="card p-3 mt-2 mb-2">
                    <h5
                      class={`card-title mb-0 ${styles.adminSession}`}
                      style={{ fontFamily: "roboto" }}
                    >
                      {" "}
                      <img
                        className="mr-3"
                        src="https://img.icons8.com/ios-glyphs/30/000000/arrow.png"
                      />
                      {q.curriculum} - {q.subject} - {q.topics} {q.sessionID}{" "}
                      <br /> <hr />
                    </h5>
                    <div class={`card-body row pt-0 ${styles.mainAdminSession}`}>
                      <div className={`col-md-6 ${styles.dateSession}`}>
                        <p
                          className={`mb-2 ${styles.head}`}
                          style={{ fontFamily: "roboto" }}
                        >
                          {q.title}
                        </p>
                        <div className={`row ${styles.dateResponsive}`}>
                          <div className="col-4 d-flex flex-column align-items-center">
                            <p className="">Start</p>
                            <p
                              className={`${styles.date}`}
                              // style={{ backgroundColor: "#4A1D75" }}
                              style={{ fontFamily: "roboto" }}
                            >
                              {start}
                            </p>
                            <p
                              className={`${styles.date} mt-2`}
                              // style={{ backgroundColor: "#4A1D75" }}
                              style={{ fontFamily: "roboto" }}
                            >
                              {moment(q.start).format("hh:mm A")}
                            </p>
                          </div>
                          <div className="col-4 d-flex flex-column align-items-center">
                            <p>End</p>
                            <p
                              className={`${styles.date} `}
                              // style={{ backgroundColor: "#4A1D75" }}
                              style={{ fontFamily: "roboto" }}
                            >
                              {end}
                            </p>
                            <p
                              className={`${styles.date} mt-2`}
                              // style={{ backgroundColor: "#4A1D75" }}
                              style={{ fontFamily: "roboto" }}
                            >
                              {moment(q.end).format("hh:mm A")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={`col-md-2 ${styles.TotalSesion}`}>
                        <p
                          className={`mb-2 ${styles.head} text-center `}
                          style={{ fontFamily: "roboto" }}
                        >
                          Total Students
                        </p>
                        <p
                          className={`${styles.num} `}
                          style={{ fontFamily: "roboto" }}
                        >
                          {len}
                        </p>
                      </div>
                      <div className={`col-md-4  text-center ${styles.sessionButton}`}>
                        <button
                          /* className={` ${styles.click2}`} */
                          className="btn btn-sm"
                          style={{ backgroundColor: "rgb(222, 242, 233)" }}
                          onClick={() => { setStudentLimit(q?.min_student ? q?.min_student : 0); saveSession(q); }}
                        >
                          <span style={{ color: "rgb(73, 175, 72)" }}>
                            <RemoveRedEyeIcon
                              style={{ color: "#49AF48", fontSize: "13px" }}
                            />
                            <b style={{ fontSize: "11px", fontFamily: "Roboto" }}>View</b>
                          </span>
                        </button>
                        <button
                          /* className={` ${styles.click1}`} */
                          className="btn btn-sm mr-2"
                          style={{
                            backgroundColor: "rgb(228, 152, 152)",
                            marginLeft: "10px",
                          }}
                          onClick={(e) => {
                            deleteAdminSession(q._id);
                          }}
                        >
                          <span style={{ color: "#ed2d2d" }}>
                            <Delete
                              style={{
                                color: "#ed2d2d",
                                fontSize: "13px",
                              }}
                            />
                            <b style={{ fontSize: "11px", fontFamily: "Roboto" }}>Delete</b>
                          </span>
                        </button>
                        <button
                          className="btn btn-sm me-2"
                          style={{
                            backgroundColor: "rgb(255, 231, 168)",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            getSessionById(q.sessionID);
                            handleShow();
                          }}
                        >
                          <span style={{ color: "#EAB731" }}>
                            <BorderColorIcon
                              style={{
                                color: "#EAB731",
                                fontSize: "13px",
                              }}
                            />
                            <b style={{ fontSize: "11px", fontFamily: "Roboto" }}>Edit</b>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>

        {changes && (
          <>
            <Modal size="lg" show={show1} onShow={handleShow}>
              <Modal.Header>
                <Modal.Title style={{ fontSize: "18px", width: "100%" }}>
                  <div className="d-flex justify-content-between">
                    <div>Edit session</div>
                    <div
                      onClick={handleClose2}
                      style={{
                        border: "2px solid black",
                        padding: "2px 10px",
                        cursor: "pointer",
                        background: "#e9ecef",
                      }}
                    >
                      x
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <form onSubmit={submitData}>
                    <div class="">
                      <div class="col-md-12 form-group d-flex align-items-center">
                        <label class="label-heading" style={{ width: "100px" }}>
                          Title
                        </label>
                        <input
                          type="text"
                          defaultValue={sessionData ? sessionData.title : ""}
                          class="form-control"
                          placeholder=""
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <lable class="label-heading " style={{ width: "100px" }}>
                          Curriculum
                        </lable>
                        <select
                          className="w-100 form-select mt-1"
                          style={{ width: "70%" }}
                          options={curriculumList}
                          value={curriculum}
                          onChange={(e) => {
                            setCurriculum(e.target.value);
                            const code = curriculumList.filter(
                              (s) => s.curriculum === e.target.value
                            );
                            console.log(code[0].curriculumCode);
                            setCurriculumCode(code[0].curriculumCode);
                            subjectFuction(code[0].curriculumCode);
                          }}
                        >
                          <option
                            defaultValue="select"
                            defaultChecked
                            selected="selected"
                            value={"select"}
                          >
                            select Curriculum
                          </option>
                          {curriculumList.map((res, index) => {
                            return (
                              <>
                                <option value={res.curriculum} key={index}>
                                  {res.curriculum}
                                </option>{" "}
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <lable class="label-heading " style={{ width: "100px" }}>
                          Subject
                        </lable>
                        <select
                          className="w-100 form-select mt-1"
                          style={{ width: "70%" }}
                          value={subject ? subject : ""}
                          onChange={(e) => {
                            setSubject(e.target.value);
                            teacherFunction(curriculumCode, e.target.value);
                            addressLists(curriculum, curriculumCode, e.target.value)
                          }}
                        >
                          <option
                            defaultValue="select"
                            defaultChecked
                            selected="selected"
                            value={"select"}
                          >
                            select Subject
                          </option>
                          {console.log(subjectList)}
                          {subjectList.map((e) => {
                            return (
                              <>
                                <option value={e.subject}>{e.subject}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <lable class="label-heading " style={{ width: "100px" }}>
                          Teachers
                        </lable>
                        <select
                          className="w-100 form-select mt-1"
                          style={{ width: "70%" }}
                          value={teacherDatas.length > 1 ? "all" : teacher}
                          onChange={(e) => {
                            setTeacher(e.target.value);
                            if (e.target.value === "all") {
                              setTeacherData(teacherData);
                            } else {
                              let teach = teacherData.filter(
                                (s) => s.email === e.target.value
                              );
                              setTeacherData(teach);
                            }
                          }}
                        >
                          <option
                            defaultValue="select"
                            defaultChecked
                            selected="selected"
                            value={"select"}
                          >
                            select
                          </option>
                          <option value="all">Invite All Teachers</option>
                          {teacherData.map((option) => (
                            <>
                              <option value={option.email}>
                                {option.firstName +
                                  " " +
                                  option.lastName +
                                  " - " +
                                  option.email}
                              </option>
                            </>
                          ))}
                          teachers
                        </select>
                      </div>

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <lable class="label-heading " style={{ width: "100px" }}>
                          Session
                        </lable>
                        <select
                          className="w-100 form-select mt-1"
                          style={{ width: "70%" }}
                          value={sessionType ? sessionType : ""}
                          onChange={(e) => {
                            let data = sessionData;
                            data.sessionType = e.target.value;
                            setSessionType(e.target.value);
                            setSessionData(data);
                          }}
                        >
                          <option
                            defaultValue="select"
                            defaultChecked
                            selected="selected"
                            value={"select"}
                          >
                            select Session Type
                          </option>
                          <option value={"Classroom"}>Classroom</option>
                          <option value={"Practical"}>Practical</option>
                        </select>
                      </div>
                      <div class="col-md-12 form-group d-flex align-items-center">
                        <lable
                          class="label-heading "
                          style={{ width: "100px" }}
                        ></lable>
                        <select
                          className="w-100 form-select mt-1"
                          style={{ width: "70%" }}
                          value={mode ? mode : ""}
                          onChange={(e) => {
                            setmode(e.target.value);
                          }}
                        >
                          <option
                            defaultValue="select"
                            defaultChecked
                            selected="selected"
                            value={"select"}
                          >
                            select Session Mode
                          </option>
                          <option value={"Online Session"}>Online Session</option>
                          <option value={"Offine Session"}>
                            Offline Session
                          </option>
                          <option value={"Offine + Online Session"}>
                            Offine + Online Session
                          </option>
                        </select>
                      </div>
                      {mode && (mode === "Offine Session" || mode === "Offine + Online Session") && (
                        <>
                          <div class="col-md-12 form-group d-flex align-items-center">
                            <label
                              class="label-heading"
                              style={{ width: "100px" }}
                            >
                              Address
                            </label>
                            <select
                              className="form-select"
                              value={address._id}
                              onChange={(e) => {
                                console.log(e.target.value)
                                const add = addressList.filter(es => es._id.toString() === e.target.value)
                                setAddress(add[0])
                              }}
                            >
                              <option
                                defaultValue="select"
                                defaultChecked
                                selected="selected"
                                value={"select"}
                                disabled
                              >
                                select
                              </option>
                              {addressList.map((res, index) =>
                              (
                                <option value={res._id} key={index}>
                                  {res.houseNo + ", " + res.area + " near by " + res.nearBy + ", " + res.city + "-" + res.pincode + ", " + res.state + ", " + res.country}
                                </option>
                              )
                              )}
                            </select>
                          </div>
                          <div class="col-md-12 form-group align-items-start">
                            <div className="d-block mb-2">
                              <b>Facilities: </b>
                            </div>
                            <div className="d-flex">
                              <div style={{ width: "100px" }}></div>
                              <div>
                                <div className="d-flex align-items-center">
                                  AC
                                  <input
                                    type="Checkbox"
                                    defaultChecked={facilities.includes("AC")}
                                    className="ms-2"
                                    value="AC"
                                    onChange={(e) => showval1(e)}
                                  />
                                </div>
                                <div className="d-flex align-items-center">
                                  Fan
                                  <input
                                    type="Checkbox"
                                    defaultChecked={facilities.includes("Fan")}
                                    className="ms-2"
                                    value="Fan"
                                    onChange={(e) => showval1(e)}
                                  />
                                </div>
                                <div className="d-flex align-items-center">
                                  Cooler
                                  <input
                                    type="Checkbox"
                                    defaultChecked={facilities.includes("Cooler")}
                                    className="ms-2"
                                    value="Cooler"
                                    onChange={(e) => showval1(e)}
                                  />
                                </div>
                                <div className="d-flex align-items-center">
                                  Refrigerator
                                  <input
                                    type="Checkbox"
                                    defaultChecked={facilities.includes(
                                      "Refrigerator"
                                    )}
                                    className="ms-2"
                                    value="Refrigerator"
                                    onChange={(e) => showval1(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/*  <b style="margin: 0px 15px 15px;">Facilities: </b>
                            <span style="margin-left: 25px; margin-right: 70px; font-size: 16px;">
                                AC
                               <input type="Checkbox" value="AC" style="margin-left: 5px;"/>
                            </span> */}

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <label class="label-heading" style={{ width: "100px" }}>
                          Description
                        </label>
                        <input
                          type="textarea"
                          class="form-control"
                          placeholder="Description"
                          style={{ width: "70%" }}
                          defaultValue={sessionData ? sessionData.desc : ""}
                          onChange={(e) => {
                            setDesc(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-md-12 form-group d-flex align-items-center">
                        <label class="label-heading" style={{ width: "100px" }}>
                          Date
                        </label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder=""
                          defaultValue={
                            sessionData ? sessionData.start.split(" ")[0] : ""
                          }
                          style={{ width: "70%" }}
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="d-flex form-group gap-3">
                        <div class="col-md-6 d-flex align-items-center">
                          <label class="label-heading" style={{ width: "118px" }}>
                            Start Time
                          </label>
                          <input
                            type="time"
                            class="form-control"
                            placeholder=""
                            defaultValue={
                              sessionData ? sessionData.start.split(" ")[1] : ""
                            }
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                          />
                          {/*  <Formik>
                      <Form>
                        <FormikControl 
                        control="input"
                        type="time"
                        label="Time"
                        name="time"
                        >
                        </FormikControl>
                      </Form>
                    </Formik> */}
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                          <label class="label-heading" style={{ width: "100px" }}>
                            End Time
                          </label>
                          <input
                            type="time"
                            class="form-control"
                            defaultValue={
                              sessionData ? sessionData.end.split(" ")[1] : ""
                            }
                            placeholder=""
                            onChange={(e) => {
                              setEndTime(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-sm mt-4"
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                        // onClick={()=>submitData()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
    </Modal.Footer> */}
            </Modal>
          </>
        )}

        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
          <Modal.Body>
            <div className="text-view mb-3 ">
              <label>Curriculum</label>
              <span>{session.curriculumCode}</span>
            </div>
            <div className="text-view mb-3">
              <label>subject</label>
              <span>{session.subject}</span>
            </div>
            {/* <div className="text-view">
                    <label>teacher</label>
                    <select
                        name='teacher'
                        // optname={optname}
                        id='teacher'
                        value={teacherList}
                        onChange={(event) => setTeacherArr(event.target.value)}
                        className="form-control"
                    >
                        <option value="">Select</option>
                        <option value="all">Invite all</option>

                        {teacherList.map((option) => {

                            

                                return (
                                    <option key={option._id} value={option._id}>
                                        {option.firstName} {option.lastName}
                                    </option>
                                )
                            
                           

                        })}
                    </select>
                   
                </div> */}
            <div className="text-view mb-3 ">
              <label>Start Date</label>
              <input
                type="date"
                onChange={(value) => {
                  changeTime("startDate", value.target.value);
                }}
                value={session.startDate}
                className="form-control"
                placeholder="date"
              />
            </div>
            <div className="text-view mb-3 ">
              <label>Start Time</label>
              <input
                type="time"
                onChange={(value) => {
                  changeTime("startTime", value.target.value);
                }}
                value={session.startTime}
                className="form-control"
                placeholder="time"
              />
            </div>

            <div className="text-view mb-3 ">
              <label>End Date</label>
              <input
                type="date"
                value={session.endDate}
                onChange={(value) => {
                  changeTime("endDate", value.target.value);
                }}
                className="form-control"
                placeholder="date"
              />
            </div>
            <div className="text-view mb-3 ">
              <label>End Time</label>
              <input
                type="time"
                value={session.endTime}
                onChange={(value) => {
                  changeTime("endTime", value.target.value);
                }}
                className="form-control"
                placeholder="time"
              />
            </div>
            {session.role === "student" && (<>

              <div className="text-view mb-3 ">
                <label>Min. Student</label>
                <input
                  type="number"
                  defaultValue={studentLimit}
                  onChange={(e) => {
                    setStudentLimit(Number(e.target.value))
                    changeTime("min_student", Number(e.target.value))
                  }}
                  className="form-control"
                  placeholder="Minimum Student"
                />
              </div>
            </>)}
            <div>
              <label>Available Teacher</label>
              <div className={styles.acceptedTecher}>
                <ul className="list-group row col-12">
                  {teacherList.map((option) => {
                    return (
                      <li
                        key={option._id}
                        className="list-group-item d-flex justify-content-between"
                      >
                        <span>
                          {" "}
                          {option.firstName} {option.lastName}
                        </span>{" "}
                        <button
                          type="button"
                          onClick={() => teachers(option, session)}
                          class="btn btn-success btn-sm"
                        >
                          add
                        </button>{" "}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <label>Accepted Teacher</label>
            <div className={styles.acceptedTecher}>
              <ul className="list-group row col-12">
                {accTeacherList.map((option) => {
                  return (
                    <li
                      key={option._id}
                      className="list-group-item d-flex justify-content-between"
                    >
                      <span>
                        {" "}
                        {option.firstName} {option.lastName}
                      </span>{" "}
                      <button
                        type="button"
                        onClick={() => removeTeacher(option)}
                        className="btn btn-sm me-2"
                        style={{
                          backgroundColor: "rgb(228, 152, 152)",
                          marginRight: "2px",
                        }}
                      >
                        <span style={{ color: "#ed2d2d" }}>
                          <Delete
                            style={{
                              color: "#ed2d2d",
                              fontSize: "13px",
                            }}
                          />
                          <b style={{ fontSize: "11px" }}>Delete</b>
                        </span>
                      </button>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
            {sendMessage && <p>Invite send to teacher</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={sendInvite}>
              Invite
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminSessionComp;
