import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
import { getInactiveUser } from "../../../services/Admin/commonService";
import styles from "./header.module.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getNotify, deleteNotify } from "../../../services/Admin/commonService";
const Header = (props) => {
  const [user, setUser] = useState([]);
  const [session, setSession] = useState([]);
  const [notify, setNotify] = useState([]);
  let history = useHistory();
  // let lis = useSelector(state => {
  //   return state.headUser.userList
  // })



  useEffect(async () => {});

  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  useEffect(() => {
    if (props.apiSession) {
      let sessionMap = props.apiSession.filter((q) => {
        if (q.student.length > 10) {
          return q;
        }
      });
      setSession(sessionMap);
    }
  }, [props.apiSession]);

  useEffect(() => {
    setUser(props.userList);
  }, [props.userList]);

  let notification = ["demoq", "demo", "demo", "demo"];
  async function getNotification() {
    await getNotify({
      for_msg: "admin",
    }).then((res) => {
      console.log(res.data.data);
      setNotify(res.data.data.reverse());
    });
  }
  useEffect(async () => {
    getNotification();
  }, []);
  // const getInactive=()=>{
  //   getInactiveUser().then(result=>{
  //     console.log(result)
  //   }).catch(err=>console.log('err header-'+err))
  // }
  const getUserInfo = (info) => {
    sessionStorage.setItem("inactiveUser", JSON.stringify(info));
  };
  return (
    <header>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.navbar}`}
      >
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* <div className={`navbar-nav ms-auto ${styles.navbarNav}`}>
            <li className="nav-item">Admin Dashboard</li>
          </div> */}
          <div
            className={`navbar-nav ml-auto ${styles.navbarNav}`}
            style={{
              display: "flex",
              justifyContent: "end",
              flexBasis: "auto",
              width: "100%",
            }}
          >
            <li
              className={`nav-item ${styles.navDashboard}`}
              style={{ marginTop: "10px" }}
            >
              Admin Dashboard
            </li>
            {/* <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-bell"></i>
              </Link>
              <div
                className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown"
              >
                <div className="flat">
                  {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })}
                  <FlatList
                    list={session}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">
                        <p>No Session</p>
                      </div>
                    )}
                    display={{
                      grid: false,
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment key={idx}>
                          <Link>
                            <div className="dropdown-item">
                              <p>{data.title} </p>
                            </div>
                          </Link>
                          {idx < notification.length - 1 && <div className="dropdown-divider"></div>}
                          <div className="dropdown-divider"></div>
                        </Fragment>
                      );
                    }}
                  />
                </div>

               
              </div>
            </li> */}

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-bell"></i>
                <span
                  class={`badge rounded-pill badge-notification bg-danger ${styles.navDashboard}`}
                  style={{
                    fontSize: "11px",
                    padding: "3px 5px",
                    margin: "-8px",
                    position: "relative",
                    top: "-19px",
                    left: "-5px",
                  }}
                >
                  {notify.length}
                </span>
              </Link>
              <div
                className={`dropdown-menu ${styles.dropdownMenu} p-0`}
                aria-labelledby="navbarDropdown"
              >
                <div className={`${styles.rejectAdmin} p-0`}>
                  {notify.map((e) => {
                    return (
                      <>
                        <div className={`${styles.notificationnn}`}>
                          <span
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              alignItems: "start",
                              padding: "15px 10px",
                              paddingBottom: "0px",
                            }}
                            onClick={() => {
                              deleteNotify(e._id).then((res) => {
                                getNotification();
                                history.push(e.path);
                              });
                            }}
                          >
                            <i class="las la-times-circle"></i>
                            <p style={{ paddingLeft: "1%" }} className={styles.navDashboard}>{e.message}</p>
                          </span>
                          <p
                            style={{
                              display: "block",
                              paddingBottom: "10px",
                              paddingLeft: "45px",
                              color: "#ccc9c9",
                            }}
                          >
                            {moment
                              .utc(e.time)
                              .local()
                              .startOf("seconds")
                              .fromNow()}
                          </p>
                        </div>
                        <hr className="m-0"></hr>
                      </>
                    );
                  })}
                </div>
                <div
                  style={{
                    position: "sticky",
                    zIndex: "9999",
                    bottom: "0px",
                    padding: "15px 15px",
                    background: "white",
                    borderTop: "1px solid #ccc9c9",
                    cursor: "pointer",
                  }}
                  className={styles.navDashboard}
                >
                  Mark all as read
                </div>
                {/* <div className="flat">
                  {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })}
                  <FlatList
                    list={user}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">
                        <p>No Notification</p>
                      </div>
                    )}
                    display={{
                      grid: false,
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment key={idx}>
                          <Link to={`/admin/inactiveUser/${data.userID}`}>
                            <div
                              className="dropdown-item"
                              onClick={() => getUserInfo(data)}
                            >
                              <p>
                                {data.firstName} {data.middleName}{" "}
                                {data.lastName}
                              </p>
                            </div>
                          </Link>
                          {idx < notification.length - 1 && (
                            <div className="dropdown-divider"></div>
                          )}
                        </Fragment>
                      );
                    }}
                  />
                </div> */}
              </div>
            </li>

            <li className={`nav-item dropdown`}>
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                // style={{ color: "#3f3d3d9f" }}
              >
                <i
                  className="las la-user-circle"
                  // style={{ color: "#3f3d3d9f" }}
                ></i>
              </a>
              <div
                className={`dropdown-menu ${styles.dropdownMenu1}`}
                aria-labelledby="navbarDropdown"
                // style={{ color: "#3f3d3d9f" }}
              >
                <Link
                  className={`dropdown-item ${styles.navDashboard}`}
                  to="/admin/profile"
                  // style={{ color: "#3f3d3d9f" }}
                >
                  Profile
                </Link>
                <div
                  className="dropdown-divider"
                  // style={{ color: "#3f3d3d9f" }}
                ></div>
                <a
                  className={`dropdown-item ${styles.navDashboard}`}
                  href="#"
                  onClick={Logout}
                  style={{}}
                >
                  Logout
                </a>
              </div>
            </li>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
