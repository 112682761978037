
import { useHistory } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { deleteAssessment } from "../../../services/Admin/assessmentService";
import { Backdrop, CircularProgress } from "@mui/material";
import { getAddressById } from "../../../services/Admin/AddressService";
import { useEffect, useState } from "react";

const ViewAssignment = ({ assignmentInvidual, questionssss, open }) => {
  const [address, setAddress] = useState()
  const data = assignmentInvidual ? assignmentInvidual : [];

  const dataAll = questionssss ? questionssss : null;
  let newArr = [];
  console.log(assignmentInvidual, "aues");

  if (dataAll === null) {
  } else {
    dataAll.map((item) => {
      console.log(item)
      newArr.push(item);
    });
    console.log(newArr, "sdsd");
  }

  async function getaddress() {
    if (data.addressId) {

      await getAddressById(data.addressId).then((e) => {
        setAddress(e.data.data);
      })
    }
  }
  useEffect(() => {
    getaddress()
  }, [data])
  // let datttaaa = [];
  // if(dataAll.testq) {
  //   datttaaa.push(dataAll.testq[0])
  // }
  // console.log(datttaaa);

  // console.log(dataAll.testq);
  const history = useHistory();
  console.log(data)
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Assessment
          </h3>
          <button
            className="btn btn-sm "
            onClick={() => {
              deleteAssessment({ testID: data.testID });
              history.goBack();
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              // marginRight: "5px",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm mr-2"
            onClick={() => history.goBack()}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Assessment Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {" "}
                    {data && data.assessmentName}{" "}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }} >
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.curriculum}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject</label>
                  <span>{data && data.subject}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Start Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && moment(data.startdate).format("DD-MM-YYYY")}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Start Time</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data &&
                      moment(data.startTime, "hh:mm").format("h:mm:ss a")}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    End Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && moment(data.enddate).format("DD-MM-YYYY")}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>End Time</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && moment(data.endTime, "hh:mm").format("h:mm:ss a")}
                  </span>
                </div>
                {/* <div className="text-view">
                <label>Lab Session</label>
                <span>{data && data.labSession === true ? "Yes" : "No"}</span>
              </div> */}

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Max Questions</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.maxQuestions}
                  </span>
                </div>

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Max Weightage</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.maxWeightage}
                  </span>
                </div>

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Correction Mode
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.correctionMode}
                  </span>
                </div>
                {address ? (<div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Address</label>
                  <div>
                  {address.houseNo + ", " + address.area + " near by " + address.nearBy + ", " + address.city + "-" + address.pincode + ", " + address.state + ", " + address.country}
                  </div>
                  <div></div>
                </div>) : (<><div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Questions</label>
                  <div>
                    {console.log(newArr.length, typeof newArr[0])}
                    {newArr.length > 0 &&
                      newArr[0] != undefined &&
                      newArr.map((data, key) => {
                        {
                          var ques = parse(data.question);
                          // ques = ques.split("?")[0];
                          // ques = ques.split("<p>").join("");
                        }
                        return <p className="mb-3">{ques}</p>;
                      })}
                  </div>
                  <div></div>
                </div></>)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAssignment;
