import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const createRole = (payload) => {
  return http.post(apiUrl + "/role/createRole", payload, {
    headers: headers,
  });
};

export const getRolesList = (payload) => {
  return http.post(apiUrl + "/role/getAllRole", payload, {
    headers: headers,
  });
};

export function saveStaff(payload,id) {
  if (!payload.userID) {
    return http.post(apiUrl + "/user/createStaff", payload, {
      headers: headers,
    });
  } else {
    delete payload._id;
    return http.post(apiUrl + "/user/updateStaff/" + id, payload, {
      headers: headers,
    });
  }
}

export const getStaffList = (payload) => {
  return http.post(apiUrl + "/user/getStaffList", payload, {
    headers: headers,
  });
};
export const getStatusChange = (payload) => {
  return http.post(apiUrl + "/user/inactiveUser", payload, {
    headers: headers,
  });
};

export const deleteStaff = async(payload) => {
 
   return http.delete(apiUrl + "/user/deleteUser/"+ payload, {
     headers: headers,
    });

};

export const deleteSession = async(payload) => {
  return http.post(apiUrl + "/session/delete/" + payload, {
    headers:headers,
  });
}
