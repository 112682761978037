import React, { useEffect, useRef, useState } from "react";
import "../../../common/Formik/Common.css";
import DatePicker from "../../../common/Formik/DatePicker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import parse from "html-react-parser";
import {
  getAssignmentByID,
  updateAssignmentById,
} from "../../../services/Admin/assessmentService";
import { Backdrop, CircularProgress } from "@mui/material";
// import { getAllCurriculumList } from '../../Share/GetCurriculumDetails';
import {
  getCurriculumList,
  getSubjectsByCur,
} from "../../../services/Admin/commonService";
import moment, { duration } from "moment";
import { useHistory } from "react-router-dom";
import { getTemplateQuestions } from "../../../services/Admin/templateService";
import { toCapitalWord } from "../../../utils/utils";
import { getQuestion } from "../../../services/Admin/questionService";
import { getTemplateList } from "../../../services/Admin/templateService";

const EditAsseement = (props) => {
  const {
    // data,
    //  curriculumList,
    //  subjectsList,

    // questionsList,
    handleInput,
  } = props;
  // console.log(data)
  // console.log(questionsList);
  const { id } = useParams();
  const history = useHistory();
  let assessmentData = useRef({
    assessmentName: "",
    type: "",
    curriculum: "",
    curriculumCode: "",
    subject: "",
    maxQuestions: "",
    maxWeightage: "",
    startdate: "",
    enddate: "",
    labsession: "",
    assessmentType: "",
    assignmentMode: "",
    optionalQuestions: "",
    optQuestionMarks: "",
    startTime: "",
    endTime: "",
    questionID: "",
    createDate: "",
    currectionMode: "",
    duration:""
  });
  let time = useRef({
    hour:  "",
    min:"" ,
    sec: "" 
  })
 

  // console.log(data)
  // let data =[];
  const [questionsList, setquestionList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [subject, setSubject] = useState("");
  const [cur, setCur] = useState("");

  const [open, setOpen] = useState(true);
  const [curriculumList, setCurriculumList] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);
  const [assignme, setAssignme] = useState(false);
  let ddd = new Date();
  let todayDate = moment(ddd).format("YYYY-MM-DD");
  const [maxQues, setMaxQues] = useState(false);
  let questionIds = [];
  let questionTypes = [];
  let correctionModeValue;
  let totalMarks = 0;

  const optioncheckbox = (e, qID, marks, questionType) => {
    if (e.target.checked) {
      questionIds.push({ questionID: qID });
      questionTypes.push(questionType);
      totalMarks += parseInt(marks);
    } else {
      let index = questionIds.findIndex((x) => x.questionID === qID);
      let index1 = questionTypes.indexOf(questionType);
      questionIds.splice(index, 1);
      questionTypes.splice(index1, 1);
      totalMarks -= parseInt(marks);
    }
    if (assessmentData.current.assignmentMode === "Mandatory") {
      if (
        questionIds.length <= assessmentData.current.maxQuestions &&
        totalMarks <= assessmentData.current.maxWeightage
      ) {
        // alert("add questions");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("max questions reached");
      }
    } else {
      if (questionIds.length <= assessmentData.current.maxQuestions) {
        // alert("add questions optional");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("max questions reached optional");
      }
    }

    function checkQuesionType(qt) {
      return qt === "Text";
    }

    let correctionMode = questionTypes.some(checkQuesionType);

    if (correctionMode == true) {
      correctionModeValue = "manual";
    } else {
      correctionModeValue = "automatic";
    }
  };

  const handleGetList = () => {
    const type = assessmentData.current.assessmentType;
    console.log(assessmentData.current);
    //  console.log(type);
    if (type === "Questions") {
      const payload = {
        curriculumCode: assessmentData.current.curriculumCode,
        subject: assessmentData.current.subject.trim(),
      };
      console.log(payload, "payload");
      getQuestion(payload).then((res) => {
        console.log(res);
        let data = res.data.data;
        let response = data.filter((e) => !e.question.includes("(copy)"));
        setquestionList(response);
      });
    } else {
      const payload = {
        curriculum: assessmentData.current.curriculum,
      };
      getTemplateList(payload)
        .then((res) => {
          console.log(res.data.data);
          console.log(assessmentData.current);
          let list = res.data.data.filter((doc) => {
            console.log(doc.curriculum);
            if (
              doc.curriculum === assessmentData.current.curriculum &&
              doc.subject === assessmentData.current.subject
            ) {
              return true;
            }
          });
          console.log(list);
          setTempList(list);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleGetTemplateQuestions = () => {
    const payload = {
      templateID: id,
    };
    getTemplateQuestions(payload)
      .then((res) => {
        console.log(res);
        let data = res.data.data;
        let response = data.filter((e) => !e.question.includes("(copy)"));
        setquestionList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const optioncheckbox = (e, qID, marks, questionType) => {
  //   if (e.target.checked) {
  //     questionIds.push({ questionID: qID });
  //     questionTypes.push(questionType);
  //     totalMarks += parseInt(marks);
  //   } else {
  //     let index = questionIds.findIndex((x) => x.questionID === qID);
  //     let index1 = questionTypes.indexOf(questionType);
  //     questionIds.splice(index, 1);
  //     questionTypes.splice(index1, 1);
  //     totalMarks -= parseInt(marks);
  //   }
  //   if (data.assignmentMode === "Mandatory") {
  //     if (
  //       questionIds.length <= data.maxQuestions &&
  //       totalMarks <= data.maxWeightage
  //     ) {
  //       // alert("add questions");
  //     } else {
  //       e.target.checked = false;
  //       let index = questionIds.findIndex((x) => x.questionID === qID);
  //       questionIds.splice(index, 1);
  //       totalMarks -= parseInt(marks);
  //       alert("max questions reached");
  //     }
  //   } else {
  //     if (questionIds.length <= data.maxQuestions) {
  //       // alert("add questions optional");
  //     } else {
  //       e.target.checked = false;
  //       let index = questionIds.findIndex((x) => x.questionID === qID);
  //       questionIds.splice(index, 1);
  //       totalMarks -= parseInt(marks);
  //       alert("max questions reached optional");
  //     }
  //   }

  //   function checkQuesionType(qt) {
  //     return qt === "Text";
  //   }

  //   let correctionMode = questionTypes.some(checkQuesionType);

  //   if (correctionMode == true) {
  //     correctionModeValue = "manual";
  //   } else {
  //     correctionModeValue = "automatic";
  //   }
  // };

  useEffect(() => {
    // handleGetTemplateQuestions();
    // handleGetList();
  }, []);
  console.log(curriculumList);
  console.log(assessmentData.current);
  useEffect(() => {
    getAssignmentByID({ testID: id }).then((res) => {
      assessmentData.current = res.data.data;
      setCur(res.data.data.curriculumCode)
      setSubject(res.data.data.subject)
      time.current.hour= Number(res.data.data?.duration.split(":")[0])
      time.current.min= Number(res.data.data?.duration.split(":")[1])
      time.current.sec= Number(res.data.data?.duration.split(":")[2])
      console.log(res.data.data);
      getCurriculumList().then((item) => {
        console.log(item);
        setCurriculumList(item.data.data);
        let data = item.data.data;
        let single = data.filter(
          (rr) => rr.curriculum == assessmentData.current.curriculum
        );
        console.log(single[0]);
        assessmentData.current.curriculum = `${single[0]?.curriculumCode}`;
        getSubjectsByCur({ curriculumCode: res.data.data.curriculumCode }).then(
          (res1) => {
            console.log(res1.data.data)
            setSubjectsList(res1.data.data);
            setOpen(false);
            console.log(res1);
          }
        );
      });
    });
  }, []);
  const updateAssignments = () => {
    setOpen(true);
    const payload = {
      assessmentName: assessmentData.current.assessmentName,
      type: assessmentData.current.type,
      curriculum: curriculumList.filter(re=>re.curriculumCode===cur)[0].curriculum,
      curriculumCode: assessmentData.current.curriculumCode,
      subject: assessmentData.current.subject,
      topic: "Topic",
      chapter: "chapter",
      maxQuestions: assessmentData.current.maxQuestions,
      maxWeightage: assessmentData.current.maxWeightage,
      startdate: assessmentData.current.startdate,
      enddate: assessmentData.current.enddate,
      labSession: "labsession",
      assessmentType: "assessmenttype",
      assignmentMode: assessmentData.current.assignmentMode,
      optionalQuestions: assessmentData.current.optionalQuestions,
      optQuestionMarks: assessmentData.current.optQuestionMarks,
      startTime: assessmentData.current.startTime,
      endTime: assessmentData.current.endTime,
     
      questions: [
        {
          questionID: assessmentData.current.questionID,
        },
      ],
      createDate: new Date(),
      correctionMode: assessmentData.current.correctionMode,
       duration: `${time.current.hour}:${time.current.min}:${time.current.sec}`,
     

    };


    console.log(payload);
    console.log(assessmentData.current);
    // return;
    updateAssignmentById(payload, id)
      .then((res) => {
        console.log(res);
        history.push("/admin/assessment");
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function getSubjectByCurriculum(e) {
    getSubjectsByCur({ curriculumCode: e}).then((res1) => {
      console.log(res1.data.data);
      setSubjectsList(res1.data.data);
      setOpen(false);
      assessmentData.current.subject = "";
    });
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!open ? (
        <>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="d-flex flex-row">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      width: "99%",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      className="form-heading"
                      style={{ color: "#4A1D75", fontFamily: "roboto" }}
                    >
                      Edit Assessment
                    </h1>
                    <button
                      className="btn btn-sm  mb-4"
                      onClick={() => history.goBack()}
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#4A1D75",
                        border: "2px solid #4A1D75",
                        width: "140px",
                        textAlign: "center",
                        padding: "6px",
                        color: "white",
                        height: "auto",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <form>
                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">
                              Assessment Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                assessmentData?.current?.assessmentName
                              }
                              onChange={(e) => {
                                // assessmentData.current.assessmentName =
                                //   e.target.value;
                                assessmentData.current.assessmentName =
                                  e.target.value;
                              }}
                              placeholder="Assessment Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="label-heading ">
                              Assessment Type
                            </label>
                            <select
                              className="w-100 form-select"
                              defaultValue={assessmentData?.current?.type}
                              onChange={(e) => {
                                assessmentData.current.type = e.target.value;
                              }}
                            >
                              <option disabled selected>
                                Select
                              </option>
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">curriculums</label>
                            <select
                              className="w-100 form-select"
                              name="curriculum"
                              optname="curriculum"
                              label="Curriulum Name"
                              value={cur}
                              onChange={(e) => {
                                getSubjectByCurriculum(e.target.value);
                                // assessmentData.current.curriculum =
                                //   e.target.value.split(",")[0];
                                assessmentData.current.curriculumCode = e.target.value
                                setCur(e.target.value)
                                assessmentData.current.curriculum = curriculumList.filter(re=>re.curriculumCode===e.target.value)[0].curriculum
                              }}
                            >
                              <option disabled selected>
                                select
                              </option>
                              {curriculumList?.map((res, index) => (
                                <option
                                  key={index}
                                  value={res.curriculumCode}
                                >
                                  {" "}
                                  {res.curriculum}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label className="label-heading">subject</label>
                            <select
                              name="subject"
                              className="w-100 form-select"
                              optname="subject"
                              label="Subject Name"
                              value={subject}
                              onChange={(e) => {
                                assessmentData.current.subject = e.target.value;
                                setSubject( e.target.value)
                              }}
                            >
                              <option disabled selected>
                                Select
                              </option>
                              {subjectsList?.reverse().map((data2, index) => (
                                <option key={index} value={data2.subject}>
                                  {" "}
                                  {data2.subject}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">
                              Start Date (MM/DD/YYYY)
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              min={todayDate}
                              placeholder="(MM/DD/YYYY)"
                              defaultValue={moment(
                                assessmentData.current.startdate
                              ).format("YYYY-MM-DD")}
                              onChange={(e) => {
                                let dd = new Date(e.target.value);
                                assessmentData.current.startdate = dd;
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="label-heading">
                              End Date (MM/DD/YYYY)
                            </label>
                            <input
                              type="date"
                              className="form-control "
                              min={todayDate}
                              placeholder="(MM/DD/YYYY)"
                              defaultValue={moment(
                                assessmentData.current.enddate
                              ).format("YYYY-MM-DD")}
                              onChange={(e) => {
                                let dd = new Date(e.target.value);
                                assessmentData.current.enddate = dd;
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">Start Time</label>
                            <input
                              type="time"
                              className="form-control"
                              defaultValue={assessmentData.current?.startTime}
                              onChange={(e) => {
                                assessmentData.current.startTime =
                                  e.target.value;
                              }}
                              placeholder="--:--"
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="label-heading">End Time</label>
                            <input
                              type="time"
                              className="form-control"
                              defaultValue={assessmentData.current?.endTime}
                              onChange={(e) => {
                                assessmentData.current.endTime = e.target.value;
                              }}
                              placeholder="--:--"
                            />
                          </div>
                        </div>

                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">
                              Assessment Mode
                            </label>
                            <select
                              className="w-100 form-select"
                              defaultValue={
                                assessmentData.current?.assignmentMode
                              }
                              onChange={(e) => {
                                assessmentData.current.assignmentMode =
                                  e.target.value;
                                if (e.target.value === "Optional") {
                                  console.log("asadasdasdasdasdasda");
                                  setAssignme(true);
                                }
                                // assignmentMode(e);
                                // handleInput(e, "assignmentMode");
                              }}
                            >
                              <option value="Mandatory">Mandatory</option>
                              <option value="Optional">Optional</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label className="label-heading">
                              Max Questions
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              placeholder=""
                              defaultValue={
                                assessmentData.current?.maxQuestions
                              }
                              onChange={(e) => {
                                assessmentData.current.maxQuestions =
                                  e.target.value;
                              }}
                            />
                          </div>
                        </div>
                        {assignme && (
                          <>
                            <div div className="form-group d-flex gap-3">
                              <div className="col-md-6">
                                <label className="label-heading">
                                  Optional Questions
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  defaultValue={
                                    assessmentData.current?.optionalQuestions
                                  }
                                  onChange={(e) => {
                                    assessmentData.current.optionalQuestions =
                                      e.target.value;
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="label-heading">
                                  Question Marks
                                </label>
                                <select
                                  type="text"
                                  className="form-control "
                                  defaultValue={
                                    assessmentData.current?.optQuestionMarks
                                  }
                                  onChange={(e) => {
                                    assessmentData.current.optQuestionMarks =
                                      e.target.value;
                                  }}
                                >
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>5</option>
                                  <option>10</option>
                                </select>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="form-group d-flex gap-3">
                          <div className="col-md-6">
                            <label className="label-heading">
                              Max Weightage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              defaultValue={
                                assessmentData.current?.maxWeightage
                              }
                              onChange={(e) => {
                                assessmentData.current.maxWeightage =
                                  e.target.value;
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="label-heading ">
                              Select Category
                            </label>
                            <select
                              className="w-100 form-select "
                              defaultValue={
                                assessmentData.current.assessmentType
                              }
                              onChange={(e) => {
                                assessmentData.current.assessmentType =
                                  e.target.value;
                              }}
                            >
                              <option value={"Questions"}>Questions</option>
                              <option value={" Use Template"}>
                                Use Template
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="time-input">
                              Assessment Duration (HH:MM:SS)
                            </label>
                            <br />
                            <input
                              className="in_put"
                              id="hh_1"
                              type="number"
                              min="0"
                              max="23"
                              defaultValue={
                                Number(assessmentData.current?.duration.split(":")[0])
                              }

                              placeholder="00"
                              onChange={(e) => {
                                time.current.hour =
                                  e.target.value;
                              }}
                            />
                            :
                            <input
                              className="in_put"
                              id="mm_1"
                              type="number"
                              min="0"
                              max="59"
                              defaultValue={Number(assessmentData.current?.duration.split(":")[1])}

                              placeholder="00"
                              onChange={(e) => {
                                time.current.min =
                                  e.target.value;
                              }}
                            />
                            :
                            <input
                              className="in_put"
                              id="ss_1"
                              type="number"
                              min="0"
                              max="59"
                              placeholder="00"
                              defaultValue={Number(assessmentData.current?.duration.split(":")[2])}
                              onChange={(e) => {
                                time.current.sec =
                                  e.target.value;
                              }}
                            />
                          </div>
                        </div>








                      </form>
                      <div className="col-md-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-sm "
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            color: "white",
                            width: "150px",
                            textAlign: "center",
                            padding: "7.5px 0px",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                          onClick={() => {
                            handleGetList();
                          }}
                        >
                          List
                        </button>
                      </div>
                      {assessmentData.current.assessmentType ===
                        "Use Template" &&
                        tempList &&
                        tempList.length > 0 && (
                          <div className="col-md-12 row mt-3 mb-3 p-0">
                            {tempList.length > 0 &&
                              tempList.map((q) => {
                                if (
                                  q.questionmarks ===
                                  assessmentData.questionMarks
                                ) {
                                  return (
                                    <div className="col-md-6" key={q._id}>
                                      <input
                                        type="radio"
                                        name="templateRadio"
                                        onClick={() =>
                                          handleGetTemplateQuestions(
                                            q.templateID
                                          )
                                        }
                                      />{" "}
                                      &nbsp;
                                      {q.templateID} - {q.templateName}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        )}

                      <div className="col-md-12 mt-4">
                        {console.log(questionsList)}
                        {questionsList && questionsList.length > 0 && (
                          <div className="col-md-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{ color: "#4A1D75" }}>ID</th>
                                  <th style={{ color: "#4A1D75" }}>Question</th>
                                  <th style={{ color: "#4A1D75" }}>
                                    Question Type
                                  </th>
                                  <th style={{ color: "#4A1D75" }}>Marks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {questionsList.length > 0 &&
                                  questionsList.map((ql) => {
                                    if (
                                      assessmentData.current.assignmentMode ===
                                      "Optional"
                                    ) {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>
                                              {ql.question
                                                ? parse(ql.question)
                                                : ""}{" "}
                                            </span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    } else if (
                                      assessmentData.current.assignmentMode ===
                                      "Mandatory"
                                    ) {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>
                                              {ql.question
                                                ? parse(ql.question)
                                                : ""}
                                            </span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                              </tbody>
                            </table>
                            <button
                              type="submit"
                              className="btn btn-primary form-btn mb-4"
                              label="Submit"
                              // disabled={!isValid}
                              // onClick={() =>
                              //   handleSubmit(
                              //     props,
                              //     questionIds,
                              //     correctionModeValue
                              //   )
                              // }
                              style={{ marginTop: "56px" }}
                            />
                          </div>
                        )}
                        <div className="text-center">
                          <button
                            // type="submit"
                            onClick={() => {
                              updateAssignments();
                            }}
                            className="btn btn-sm  mt-4"
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "#4A1D75",
                              border: "2px solid #4A1D75",
                              width: "140px",
                              textAlign: "center",
                              padding: "6px",
                              color: "white",
                              height: "auto",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default EditAsseement;
